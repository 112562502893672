import axios from "../libs/axios";

interface SpuListParams {
  q?: string;
  page?: number;
  limit?: number;
  cat?: string;
}

interface SpuSelectionParams {
  q?: number;
  page?: number;
  limit?: number;
  stock?: number;
  cat?: string;
  full?: number;
}

interface PointsData {
  spu_id: number;
  valid_start: number;
  valid_end: number;
  use_formula: boolean;
  q_coordinates: number[];
  h_coordinates: number[];
  eff_coordinates: number[];
  npsh_coordinates?: number[];
  size: number;
  freq: number;
  speed: number;
}

const getList = async (params?: SpuListParams) =>
  await axios.get("/spus", { params });

const getSpuName = async (id: string) => await axios.get(`/spus/${id}`);

const getInfo = async (id: string) => await axios.get(`/spus/${id}/info`);

const getPoints = async (id: string) => await axios.get(`/spus/${id}/points`);

const postPoints = async (id: string, points: PointsData) =>
  await axios.post(`/spus/${id}/points`, points);

const putPoints = async (id: string, points: PointsData) =>
  await axios.put(`/spus/${id}/points`, points);

const deletePoints = async (id: string) =>
  await axios.delete(`/spus/${id}/points`);

const getPdfParameter = async (id: string) =>
  await axios.get(`/spus/${id}/pdfParameter`);

const postPdfExport = async (id: string, params: any) =>
  await axios.post(`/spus/${id}/exportPdf`, params, { responseType: "blob" });

const getCategories = async () => await axios.get("/categories");

const getSelection = async (params?: SpuSelectionParams) =>
  await axios.get("/selection", { params });

const getSizeAndAttrByLabel = async (
  id: string,
  sizeLabel?: string,
  attrLabel?: string
) =>
  await axios.get(`/spus/${id}/label?size=${sizeLabel}&attribute=${attrLabel}`);

const postExcelExport = async (id: string) => 
  await axios.post(`/spus/${id}/exportAttrExcel`, {}, { responseType: "blob" });

  const postSizeBatch = async (params: any) =>
  await axios.post('/spus/export/sizeExcel', params, { responseType: "blob" });

const postExcelBatch = async (params: any) =>
  await axios.post('/spus/export/attrExcel', params, { responseType: "blob" });

const postCurveBatch = async (params: any) =>
  await axios.post('/spus/points/export', params, { responseType: "blob" });

const getSpuAttrInfo = async (id: String) =>
  await axios.get(`/spus/${id}/attrInfo`);

const putSpuAttrInfo = async (id: string, params: any) =>
  await axios.put(`/spus/${id}/attrInfo`, params);

const getSpuSizeInfo = async (id: String) =>
  await axios.get(`/spus/${id}/sizeInfo`);

const putSpuSizeInfo = async (id: string, params: any) =>
  await axios.put(`/spus/${id}/sizeInfo`, params);

const putSpuInfo = async (id: string, params: any) =>
  await axios.put(`/spus/${id}/info`, params);

const addSpuInfo = async (params: any) =>
  await axios.post(`/spus`, params);

// 查询SPU下的SKU列表
const getSkuListBySpu = async (id: any, params: any) =>
  await axios.get(`/spus/${id}/getSkuListBySpu`, { params });

// 调用类似SPU模型列表
const postSameSpuList = async (params: any) =>
  await axios.post('/spus/getSameSpuList', params)

// 调用类似SPU模型详情
const postSameSpuInfo = async (params: any) =>
  await axios.post('/spus/getSameSpuInfo', params)

// SPU下的SKU规格和属性保存 saveType 0: 规格, 1: 属性
const saveSkuListBySpu = async (params: any) =>
  await axios.post('/spus/saveSkuListBySpu', params);

// SPU下的SKU非标规格保存
const saveSkuCusSpecBySpu = async (params: any) =>
  await axios.post('/spus/saveSkuCusSpecBySpu', params);

const getPdfPreviewData = async (id: number) =>
  await axios.get(`/spus/${id}/pdfPreviewData`)

const deleteSpuSizeInfo = async (id: number) =>
  await axios.delete(`/spus/${id}/info`)

const spuServices = {
  getSpuName,
  getList,
  getInfo,
  getPoints,
  postPoints,
  putPoints,
  deletePoints,
  getPdfParameter,
  postPdfExport,
  getCategories,
  getSelection,
  getSizeAndAttrByLabel,
  postExcelExport,
  postSizeBatch,
  postExcelBatch,
  postCurveBatch,
  getSpuAttrInfo,
  putSpuAttrInfo,
  getSpuSizeInfo,
  putSpuSizeInfo,
  putSpuInfo,
  addSpuInfo,
  getSkuListBySpu,
  postSameSpuList,
  postSameSpuInfo,
  saveSkuListBySpu,
  saveSkuCusSpecBySpu,
  getPdfPreviewData,
  deleteSpuSizeInfo
};

export default spuServices;
