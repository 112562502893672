import "./index.css";
import { useEffect, useState } from "react";
import { 
  PageHeader, 
  Button,
  Card,
  ConfigProvider,
  Table,
  Form,
  Input,
  Modal,
  message,
  Menu,
  Dropdown,
  Upload
} from 'antd';
import { UnorderedListOutlined, DownOutlined, ExclamationCircleOutlined, UploadOutlined } from '@ant-design/icons';
import { useTranslation } from "react-i18next";
import BaseLayout from "../../components/BaseLayout";
import documentServices from '../../services/document';
import { BASE_URL } from "../../libs/axios";
import useAuth from "../../hooks/useAuth";

import { langMap } from "../../i18n"

export default function DocumentList() {

  const { t, i18n } = useTranslation()
  const { auth }: any = useAuth();
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [infoVisible, setInfoVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [uploading, setUploading] = useState(false);
  const [documentID, setDocumentID] = useState('')
  const [editData] = Form.useForm()
  const { confirm } = Modal

  const columns = [
    { key: "id", title: "id", dataIndex: "id", width: 80, textWrap: 'word-break' },
    { key: "name", title: t("document.columns.documentName"), dataIndex: "documentName", ellipsis: true, width: 200 },
    { 
      key: "file", 
      title: t("document.columns.documentFile"), 
      dataIndex: "documentFile", 
      ellipsis: true,
      width: 200,
      render: (_: any, record: any) => {
        return (
          <a rel="noreferrer" className="link-cls" target="_blank" href={record.documentFile}>{record.documentFile}</a>
        );
      },
    },
    { key: "time", title: t("document.columns.createTime"), dataIndex: "createTime", ellipsis: true, width: 120, textWrap: 'word-break', },
    { 
      key: "action",
      title: t("document.columns.action"),
      dataIndex: "action",
      width: 120,
      textWrap: 'word-break',
      fixed: "right" as const,
      render: (_: any, record: any) => {
        const menu = (
          <Menu>
            <Menu.Item key="edit" onClick={() => onHandleEdit(record)}>{t("document.action.edit")}</Menu.Item>
            <Menu.Item key="del" onClick={() => onHandleDel(record.id)}>{t("document.action.delete")}</Menu.Item>
          </Menu>
        );

        return (
          <Dropdown overlay={menu}>
            <Button type="text" icon={<UnorderedListOutlined />}>
              <DownOutlined />
            </Button>
          </Dropdown>
        );
      },
    },
  ];

  useEffect(() => {
    getData()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getData = async () => {
    setLoading(true)
    const res: any = await documentServices.getList();
    const { response } = res.data
    if(response) {
      const { list } = response
      setDataSource(
        list.map((item: any) => ({
          id: item.id,
          createTime: item.create_time,
          documentFile: item.document_file,
          documentName: item.document_name,
          status: item.status
        }))
      )
    }
    setLoading(false)
  }

  const onHandleEdit = (item: any) => {
    setInfoVisible(true)
    setDocumentID(item === 'add'?'':item.id)
    editData.setFieldsValue({
      documentName: item === 'add'?'':item.documentName,
      documentFile: item === 'add'?'':item.documentFile
    })
  }

  const onHandleDel = async (id: any) => {
    confirm({
      title: t("document.delete.warning"),
      icon: <ExclamationCircleOutlined />,
      content: t("document.delete.content"),
      centered: true,
      onOk: async () => {
        try {
          const result = await documentServices.documentDelete({id: Number(id)});
          const { response } = result.data;
          if(response) {
            message.success(t("document.delete.success"))
            getData()
          }
        } catch (error) {
          message.error("Error")
        }
      },
      onCancel() {
        
      }
    });
  }

  const onEditFinished = async () => {
    let params = editData.getFieldsValue();
    try {
      let index = params.documentFile.lastIndexOf('/') 
      if(index > -1) {
        params["documentFile"] = params.documentFile.substring(index + 1, params.documentFile.length)
      }
      if(documentID) {
        params["id"] = Number(documentID)
        const res = await documentServices.documentUpdate(params);
        const { response } = res.data;
        if(response) {
          setInfoVisible(false)
          getData()
        }
      } else {
        const res = await documentServices.documentInsert(params);
        const { response } = res.data;
        if(response) {
          setInfoVisible(false)
          getData()
        }
      }
    } catch (error) {
      message.error("Error")
    }
  }

  const onEditFinishFailed = () => {
    message.error("Error")
  }

  const beforeUpload = (file: any) => {
    const isFile = (
      file.type === 'application/msword' 
      || file.type === 'application/pdf' 
      || file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    );
    if (!isFile) {
      message.error(t("document.form.uploadMsg"));
    }
    return isFile || Upload.LIST_IGNORE;
  }

  return (
    <BaseLayout title={t("route.documentList")}>
      <PageHeader
        title={t("route.documentList")}
        style={{ padding: "16px 0" }}
        extra={[
          <Button key="new" type="primary" onClick={() => onHandleEdit('add')}>
            {t("document.create")}
          </Button>,
        ]}
      />

      <Card style={{ marginTop: 24 }}>
        <ConfigProvider locale={langMap(i18n.language)}>
          <Table
            columns={columns}
            rowKey={record=>record.id}
            bordered={false}
            dataSource={dataSource}
            scroll={{ x: 325 }}
            pagination={false}
            loading={loading}
          />
        </ConfigProvider>
      </Card>

      <Modal centered open={infoVisible} onCancel={() => setInfoVisible(false)} footer={null}>
        <h3 className="edit-title">{documentID?t("document.edit"):t("document.create")}</h3>
        <Form
          name="basic"
          labelCol={{ span: 7 }}
          wrapperCol={{ span: 14 }}
          form={editData}
          onFinish={onEditFinished}
          onFinishFailed={onEditFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label={t("document.form.documentName")}
            name="documentName"
            getValueFromEvent={(e)=>{ return e.target.value.replace(/(^\s*)|(\s*$)/g, ''); }}
            rules={[{ required: true, message: t("document.form.pleaseName") }]}
          >
            <Input placeholder={t("document.form.placeholder")} />
          </Form.Item>
          <Form.Item
            name="documentFile"
            label={t("document.form.documentFile")}
            getValueFromEvent={(e)=>{ return e.target.value.replace(/(^\s*)|(\s*$)/g, ''); }}
            rules={[{ required: true, message: t("document.form.pleaseFile") }]}
          >
            <Input.Group compact className="document-upload">
              <Form.Item
                name="documentFile"
                noStyle
              >
                <Input readOnly placeholder={t("document.form.placeholder")} />
              </Form.Item>
              <Form.Item noStyle>
                <Upload 
                  name="file"
                  action={`${BASE_URL}/file/insert`}
                  headers={{ authorization: `Bearer ${auth.token}` }}
                  accept=".doc, .docx, .pdf" 
                  showUploadList={false}
                  beforeUpload={beforeUpload}
                  onChange={(info: any) => {
                    if (info.file.status === "uploading") {
                      setUploading(true);
                    }
                    if (info.file.status === "uploading" && !uploading) {
                      setUploading(true);
                    }
                    if (info.file.status === "done") {
                      if(info.file.response) {
                        const { response } = info.file.response
                        if(response) {
                          editData.setFieldValue('documentFile', response.address + response.file_name) 
                        }
                      }
                    }
                    if (info.file.status === "error") {
                      message.error(t("fileManager.tool.upload.failed"));
                    }
                  }}>
                  <Button type="primary" icon={<UploadOutlined />}>{t("document.form.upload")}</Button>
                </Upload>
              </Form.Item>
            </Input.Group>
          </Form.Item>
           <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button className="edit-btn-cancel" onClick={() => setInfoVisible(false)}>{t("document.form.cancel")}</Button>
            <Button type="primary" htmlType="submit">{t("document.form.submit")}</Button>
          </Form.Item>
        </Form>
      </Modal>
    </BaseLayout>
  )
}