import { Button, Form, Radio, Input } from "antd";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import useSpuPoints from "../../../stores/spuPoints";

const { useForm } = Form;

const Unit = ({ name, style }: { name: string; style?: any }) => {
  return <span style={{ display: "block", width: 30, ...style }}>{name}</span>;
};

let oldQuantity: any = '';
let oldHead: any = '';
let oldSg: any = '';

const PerformanceForm = () => {
  const [form] = useForm();
  const { t } = useTranslation();
  
  const { info, performance, setPerformanceValue, resetPerformance, setPerformanceForm } = useSpuPoints();

  const [searchParams, setSearchParams] = useSearchParams();

  const { type } = info;

  const paramQ = Number(searchParams.get("q"));
  const paramH = Number(searchParams.get("h"));

  const { fieldsValue } = performance;
  const { quantity, head } = fieldsValue;

  useEffect(() => {
    setPerformanceForm(form);

    if (quantity && head) {
      return;
    }

    if (paramQ && paramH) {
      form.setFieldsValue({
        quantity: paramQ,
        head: paramH,
      });

      setPerformanceValue({
        type: 0,
        quantity: paramQ,
        head: paramH,
        sg: 1,
      });
    }

    oldQuantity = paramQ || '';
    oldHead = paramH || '';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (values: any) => {
    const {
      type,
      quantity,
      head,
      sg
    } = values;

    if (quantity === undefined || head === undefined) return;

    const finalQ: any = Number(quantity);
    const finalH: any = Number(head);

    setPerformanceValue({
      type,
      quantity: finalQ,
      head: finalH,
      sg: sg || 1,
    });

    if (paramQ && paramH) {
      setSearchParams({
        q: finalQ,
        h: finalH,
      });
    }
  };

  const handleReset = () => {
    form.resetFields();
    resetPerformance();
    setSearchParams({});
  };

  const handleQuantityBlur = () => {
    let {
      quantity: finalValue
    } = form.getFieldsValue();

    if (finalValue === '') {
      form.setFieldsValue({
        quantity: finalValue
      });
  
      oldQuantity = finalValue;

      return;
    }

    const finalVs = `${finalValue}`.split('.');

    const first = finalVs[0] || '0';
    let two = finalVs[1] || '0';
    two = two.slice(0, 1);

    finalValue = `${first}${two ? '.' : ''}${two}`;

    form.setFieldsValue({
      quantity: finalValue
    });

    oldQuantity = finalValue;
  }

  const handleQuantityChange = (e: any) => {
    const length = oldQuantity.length;

    const value = e.target.value;
    const lengthTwo = value.length
    
    if (lengthTwo > length) {
      let newValue = value.slice(length);
  
      newValue = newValue.replace(/\D/g, '.');
  
      let finalValue = `${oldQuantity}${newValue}`;
  
      form.setFieldsValue({
        quantity: finalValue
      });
  
      oldQuantity = finalValue;
    } else {
      form.setFieldsValue({
        quantity: value
      });
  
      oldQuantity = value;
    }
  }

  const handleHeadBlur = () => {
    let {
      head: finalValue
    } = form.getFieldsValue();

    if (finalValue === '') {
      form.setFieldsValue({
        head: finalValue
      });
  
      oldHead = finalValue;

      return;
    }

    const finalVs = `${finalValue}`.split('.');

    const first = finalVs[0] || '0';
    let two = finalVs[1] || '0';
    two = two.slice(0, 1);

    finalValue = `${first}${two ? '.' : ''}${two}`;

    form.setFieldsValue({
      head: finalValue
    });

    oldHead = finalValue;
  }

  const handleHeadChange = (e: any) => {
    const length = oldHead.length;

    const value = e.target.value;
    const lengthTwo = value.length
    
    if (lengthTwo > length) {
      let newValue = value.slice(length);
  
      newValue = newValue.replace(/\D/g, '.');
  
      let finalValue = `${oldHead}${newValue}`;
  
      form.setFieldsValue({
        head: finalValue
      });
  
      oldHead = finalValue;
    } else {
      form.setFieldsValue({
        head: value
      });
  
      oldHead = value;
    }
  }

  const handleSgBlur = () => {
    let {
      sg: finalValue
    } = form.getFieldsValue();

    if (finalValue === '') {
      form.setFieldsValue({
        sg: finalValue
      });
  
      oldSg = finalValue;

      return;
    }

    const finalVs = `${finalValue}`.split('.');

    const first = finalVs[0] || '0';
    let two = finalVs[1] || '00';
    two = two.slice(0, 2);

    finalValue = `${first}${two ? '.' : ''}${two}`;

    const number = Number(finalValue);

    if (number < 0.2) {
      finalValue = 0.2;
    }

    if (number > 5.0) {
      finalValue = 5.0;
    }

    form.setFieldsValue({
      sg: finalValue
    });

    oldSg = finalValue;
  }

  const handleSgChange = (e: any) => {
    const length = oldSg.length;

    const value = e.target.value;
    const lengthTwo = value.length
    
    if (lengthTwo > length) {
      let newValue = value.slice(length);
  
      newValue = newValue.replace(/\D/g, '.');
  
      let finalValue = `${oldSg}${newValue}`;
  
      form.setFieldsValue({
        sg: finalValue
      });
  
      oldSg = finalValue;
    } else {
      form.setFieldsValue({
        sg: value
      });
  
      oldSg = value;
    }
  }

  return (
    <Form
      form={form}
      name="settings"
      layout="vertical"
      onReset={handleReset}
      onFinish={handleSubmit}
      initialValues={{ type: 0, sg: 1 }}
    >
      <Form.Item label={t("chart.formula")} name="type">
        <Radio.Group>
          <Radio.Button value={0}>{t("spuForm.formula.select.one")}</Radio.Button>
          <Radio.Button value={1} disabled={!type}>
            {t("spuForm.formula.select.two")}
          </Radio.Button>
        </Radio.Group>
      </Form.Item>
      <Form.Item label="Q" name="quantity" required>
        <Input
          style={{ width: "100%" }}
          addonAfter={<Unit name="m³/h" />}
          onChange={handleQuantityChange}
          onBlur={handleQuantityBlur}
        />
      </Form.Item>
      <Form.Item label="H" name="head" required>
        <Input
          style={{ width: "100%" }}
          addonAfter={<Unit name="m" />}
          onChange={handleHeadChange}
          onBlur={handleHeadBlur}
        />
      </Form.Item>
      <Form.Item label={t("chart.sg")} name="sg">
        <Input
          style={{ width: "100%" }}
          onChange={handleSgChange}
          onBlur={handleSgBlur}
        />
      </Form.Item>
      <Form.Item noStyle>
        <Button type="primary" htmlType="submit">
          {t("chart.submit")}
        </Button>
        <Button type="ghost" htmlType="reset" style={{ marginLeft: 10 }}>
          {t("chart.clear")}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default PerformanceForm;
