import { useState, useEffect } from 'react';
import { Button, Drawer, Input, Spin, Popconfirm } from "antd";
import { useParams } from "react-router-dom";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { useTranslation } from "react-i18next";

import useDrawer from "../../../hooks/useDrawer";
import useLoading from "../../../hooks/useLoading";

import useSpuInfo from '../../../stores/spuInfo';
import useSpuPoints from "../../../stores/spuPoints";

import "./attr.css";

const Attr = () => {
  const [infoLoading, setInfoLoading] = useState(false);
  const [attrLabel, setAttrLabel] = useState([]);
  const [attrData, setAttrData] = useState([]);
  const [attrId, setAttrId] = useState({});
  const [attrValueId, setAttrValueId] = useState({});

  const { t } = useTranslation();
  const { xs } = useBreakpoint();
  const { id } = useParams();

  const {
    getSpuAttrInfo,
    putSpuAttrInfo
  } = useSpuPoints();

  const {
    fetch: fetchInfo
  } = useSpuInfo();

  const { visible, show, hide, setVisible } = useDrawer();

  const drawerWidth = xs ? 378 : 520;

  const getInfo = async (id: any) => {
    setInfoLoading(true);

    const data = await getSpuAttrInfo(id);

    const {
      data: rData,
      label
    } = data;

    setAttrData(rData);
    setAttrLabel(label);

    setInfoLoading(false);
  }

  const handleSubmit = async () => {
    const finalAttrInfo = attrData;

    finalAttrInfo.forEach((v: any, i: any) => {
      const {
        attr_id,
        attr_item_id
      } = v;

      let finalAttrId = attr_id;
      let finalAttrValueId = attr_item_id;

      const stateAttrId = (attrId as any)[i];
      const stateAttrValueId = (attrValueId as any)[i];

      if (stateAttrId !== undefined && stateAttrId !== finalAttrId) {
        finalAttrId = stateAttrId;
      }

      if (stateAttrValueId !== undefined && stateAttrValueId !== finalAttrValueId) {
        finalAttrValueId = stateAttrValueId;
      }

      v.attr_id = finalAttrId;
      v.attr_item_id = finalAttrValueId;
    });

    await putSpuAttrInfo(id, finalAttrInfo, attrLabel);

    if (id) {
      fetchInfo(id, false);
    }

    setVisible(false);

    setAttrId({});
    setAttrValueId({});
  };

  const handleAttrIdChange = (e: any, i: any) => {
    const value = e.target.value;

    const finalAttrId: any = {
      ...attrId,
      [i]: value
    }

    setAttrId(finalAttrId);
  }

  const handleAttrValueIdChange = (e: any, i: any) => {
    const value = e.target.value;

    const finalAttrValueId: any = {
      ...attrValueId,
      [i]: value
    }

    setAttrValueId(finalAttrValueId);
  }

  const handleLabelChange = (e: any) => {
    const value = e.target.value;

    setAttrLabel(value);
  }

  const handleDelete = async (i: any) => {
    const finalAttrInfo = attrData;

    const filterAttrInfo = finalAttrInfo.filter((v: any, index: any) => index !== i);

    await putSpuAttrInfo(id, filterAttrInfo, attrLabel);

    setAttrData(filterAttrInfo);

    if (id) {
      fetchInfo(id, false);
    }
  }

  const handleAddAttrClick = () => {
    const add: any = {
      attr_id: '',
      attr_item_id: '',
      attr_item_name: '',
      attr_name: ''
    }

    const attr = attrData || [];

    const finalAttr: any = [
      ...attr,
      add
    ];

    setAttrData(finalAttr);
  }

  useEffect(() => {
    if (id && visible) {
      getInfo(id);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, visible]);

  const [onSubmit, loading] = useLoading(handleSubmit);

  return (
    <>
      <Button type="default" onClick={show}>
        {t("spuChart.tools.attr")}
      </Button>
      <Drawer
        title={t("spuChart.tools.attr")}
        onClose={hide}
        visible={visible}
        destroyOnClose
        width={drawerWidth}
        className="spu-attr-drawer"
        extra={
          <Button
            type="primary"
            onClick={onSubmit}
            loading={loading}
          >
            {t("spuChart.tools.customDrawer.submit")}
          </Button>
        }
      >
        <div
          className="sta-attr"
          style={{
            width: "100%",
            height: "100%",
            overflowY: "scroll",
          }}
        >
          {
            infoLoading && (
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <Spin />
              </div>
            )
          }
          {
            !infoLoading && (
              <div className="sta-attr-info">
                <div
                  className="sta-ai-label-div"
                  style={{
                    marginBottom: "24px",
                    paddingBottom: "24px",
                    borderBottom: "1px solid #dddddd"
                  }}
                >
                  <div
                    className="sta-aild-title"
                    style={{
                      display: "inline-block",
                      fontSize: "16px"
                    }}
                  >
                    {t("spuInfo.label.title")}:
                  </div>
                  <div
                    className="sta-alid-label"
                    style={{
                      display: "inline-block",
                      marginLeft: "12px"
                    }}
                  >
                    <Input
                      placeholder={t("spuChart.size.labelNamePlaceHodler")}
                      value={attrLabel}
                      onChange={(e: any) => handleLabelChange(e)}
                    />
                  </div>
                </div>
                {
                  (attrData && attrData.length > 0) && attrData.map((v: any, i: any) => {
                    const {
                      attr_id,
                      attr_name,
                      attr_item_id,
                      attr_item_name
                    } = v;

                    let finalAttrId = attr_id;

                    const stateAttrId = (attrId as any)[i];

                    if (stateAttrId !== undefined && stateAttrId !== finalAttrId) {
                      finalAttrId = stateAttrId;
                    }

                    let finalAttrValueId = attr_item_id;

                    const stateAttrValueId = (attrValueId as any)[i];

                    if (stateAttrValueId !== undefined && stateAttrValueId !== finalAttrValueId) {
                      finalAttrValueId = stateAttrValueId;
                    }

                    return (
                      <div
                        key={i}
                        className="sta-ai-list"
                        style={{
                          marginBottom: "24px",
                          paddingBottom: "24px",
                          borderBottom: "1px solid #dddddd"
                        }}
                      >
                        <Popconfirm
                          title={t("spuChart.attr.delete")}
                          okText={t("confirm.ok")}
                          cancelText={t("confirm.cancel")}
                          onConfirm={() => handleDelete(i)}
                        >
                          <div className="sta-ail-delete" />
                        </Popconfirm>
                        <div className="sta-ali-list">
                          <div
                            className="sta-ail-title"
                            style={{
                              display: "inline-block"
                            }}
                          >
                            {t("spuChart.attr.name")}:
                          </div>
                          <div
                            className="sta-ali-label"
                            style={{
                              display: "inline-block",
                              marginLeft: "12px"
                            }}
                          >
                            {attr_name}
                          </div>
                        </div>
                        <div className="sta-ali-list">
                          <div
                            className="sta-ail-title"
                            style={{
                              display: "inline-block"
                            }}
                          >
                            {t("spuChart.attr.id")}:
                          </div>
                          <div
                            className="sta-ali-label"
                            style={{
                              display: "inline-block",
                              marginLeft: "12px"
                            }}
                          >
                            <Input
                              placeholder={t("spuChart.attr.valueId")}
                              value={finalAttrId}
                              onChange={(e: any) => handleAttrIdChange(e, i)}
                            />
                          </div>
                        </div>
                        <div className="sta-ali-list">
                          <div
                            className="sta-ail-title"
                            style={{
                              display: "inline-block"
                            }}
                          >
                            {t("spuChart.attr.valueName")}:
                          </div>
                          <div
                            className="sta-ali-label"
                            style={{
                              display: "inline-block",
                              marginLeft: "12px"
                            }}
                          >
                            {attr_item_name}
                          </div>
                        </div>
                        <div className="sta-ali-list">
                          <div
                            className="sta-ail-title"
                            style={{
                              display: "inline-block"
                            }}
                          >
                            {t("spuChart.attr.valueId")}:
                          </div>
                          <div
                            className="sta-ali-label"
                            style={{
                              display: "inline-block",
                              marginLeft: "12px"
                            }}
                          >
                            <Input
                              placeholder={t("spuChart.attr.valueIdPlaceholder")}
                              value={finalAttrValueId}
                              onChange={(e: any) => handleAttrValueIdChange(e, i)}
                            />
                          </div>
                        </div>
                      </div>
                    )
                  })
                }
                <div className='sta-add-data'>
                  <Button
                    type="primary"
                    className='sta-ad-button'
                    onClick={handleAddAttrClick}
                  >
                    {t("spuChart.attr.add")}
                  </Button>
                </div>
              </div>
            )
          }
        </div>
      </Drawer>
    </>
  );
};

export default Attr;
