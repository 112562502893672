import { Button, Drawer, Form } from "antd";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useDrawer from "../../../hooks/useDrawer";
import useLoading from "../../../hooks/useLoading";
import spuServices from "../../../services/spu";
import useSpuInfo from "../../../stores/spuInfo";
import useSpuPdfParam from "../../../stores/spuPdfParam";
import PdfExport from "../Description/PdfExport";

const { useForm } = Form;

interface PrintPdfProps {
  buttonType: "primary" | "default";
}

const PrintPdf = ({ buttonType = "primary" }: PrintPdfProps) => {
  const [form] = useForm();
  const { id } = useParams();
  const { t } = useTranslation();
  const { modules, paperSize, margin, company, filename, parameters, motor, images, tempList, type } = useSpuPdfParam();
  const { selectedSkuAndSpecData } = useSpuInfo();

  const { visible, show, hide, setVisible } = useDrawer();

  const handleSubmit = async () => {
    // 获取格式化的 PDF 参数
    const values = await form.validateFields();

    // 图片
    const chartImages = Object.values(images).filter((item) => item !== "");

    // 时间
    const date = values.company.date
      ? values.company.date.format("YYYY-MM-DD")
      : "";

    const modelViews = values.modules.filter((item: any) => item.checked);

    let params: any = {
      type: values.type,
      pageSet: {
        format: values.paperSize,
        margin_left: values.margin.left,
        margin_right: values.margin.right,
        margin_top: values.margin.top,
        margin_bottom: values.margin.bottom,
      },
      companyHead: {
        name: values.company.name,
        founder: values.company.founder,
        mobile: values.company.phone,
        email: values.company.email,
        date,
      },
      modelView: modelViews.map((item: any) => item.key),
      pdfData: {
        text: values.custom
          ? values.custom.map((item: any) => ({
              name: item.key,
              value: item.value,
            }))
          : [],
        images: chartImages,
      },
    };

    if (selectedSkuAndSpecData) {
      const { sku, specData } = selectedSkuAndSpecData;
      params = { ...params, sku, specData };
    }

    if (params.type === 2 || params.type === '2') {
      params["parameters"] = values.parameters;
      params["motor"] = values.motor;
    }
    
    // 导出 PDF
    const res = await spuServices.postPdfExport(String(id), params);
    const { data } = res;
    const url = window.URL.createObjectURL(
      new Blob([data], { type: "application/pdf" })
    );
    const download = document.createElement("a");
    download.href = url;
    download.download = `${values.filename || filename}.pdf`;
    download.click();

    setVisible(false);
    form.resetFields();
  };

  const [onSubmit, loading] = useLoading(handleSubmit);

  return (
    <>
      <Button type={buttonType} onClick={show}>
        {t("spuInfo.specForm.exportPdf")}
      </Button>
      <Drawer
        title={t("spuChart.pdfExport.title")}
        visible={visible}
        onClose={hide}
        destroyOnClose
        extra={
          <Button type="primary" onClick={onSubmit} loading={loading}>
            {t("spuChart.tools.printPdf.submit")}
          </Button>
        }
      >
        {/* PrintPdf */}
        <PdfExport
          formRef={form}
          initialValues={{ modules, paperSize, margin, company, filename, parameters, motor, tempList, type }}
        />
      </Drawer>
    </>
  );
};

export default PrintPdf;
