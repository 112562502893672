// 编辑模型
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Form,
  Tabs,
  Card,
  Spin,
  Empty,
  Input,
  Space,
  Drawer,
  Button,
  Tooltip,
  message,
  PageHeader,
  InputNumber
} from "antd";

import {
  PlusOutlined,
  CloseOutlined,
  PlusCircleOutlined
} from "@ant-design/icons";

import { useParams } from "react-router-dom";

import useLoading from "../../hooks/useLoading";

import useModel from "../../stores/model";

import { nameLangMap } from "../../i18n";

import { getUrlParam } from "../../utils";

import AttributeChunk from "./AttributeChunk";
import CreateAttribute from "./CreateAttribute";

import InputName from "../../components/InputName";
import BaseLayout from "../../components/BaseLayout";
import EditableText from "../../components/EditableText";

import "./AttritubeAtom.css";

const { useForm } = Form;

const Model = () => {
  const [specForm] = useForm();
  const [specListForm] = useForm();
  const [cusSpecForm] = useForm();

  const { id } = useParams();

  const { t, i18n } = useTranslation();

  const nowLang: any = nameLangMap(i18n.language);

  // no data
  const [emptyFlag, setEmptyFlag] = useState(false);

  // tab key
  const [tabKey, setTabKey] = useState('0');

  // 规格 drawer visible
  const [specVisible, setSpecVisible] = useState(false);

  // 规格 修改的 index
  const [ specEditIndex, setSpecEditIndex ] = useState(-1);

  const specObj: any = {
    name: {
      zh: "",
      en: "",
      ru: "",
    },
    order: 100,
    code: ''
  };

  // 规格 drawer 默认数据
  const [ specValues, setSpecValues ] = useState(specObj);

  // 规格list drawer visible
  const [specListVisible, setSpecListVisible] = useState(false);

  // 规格 修改的 index
  const [ specListEditParentIndex, setSpecListEditParentIndex ] = useState(-1);
  const [ specListEditIndex, setSpecListEditIndex ] = useState(-1);

  const specListObj: any = {
    name: {
      zh: "",
      en: "",
      ru: "",
    },
    order: 100,
    code: ''
  };

  // 规格 drawer 默认数据
  const [ specListValues, setSpecListValues ] = useState(specListObj);

  // 非标规格 drawer visible
  const [cusSpecVisible, setCusSpecVisible] = useState(false);

  // 非标规格 修改的 index
  const [ cusSpecEditIndex, setCusSpecEditIndex ] = useState(-1);

  const cusSpecObj: any = {
    name: {
      zh: "",
      en: "",
      ru: "",
    },
    order: 100,
    code: ''
  };

  // 非标规格 drawer 默认数据
  const [ cusSpecValues, setCusSpecValues ] = useState(cusSpecObj);

  // 属性相关
  // 属性 添加 drawer visible
  const [attrVisible, setAttrVisible] = useState(false);

  // 属性list 添加 drawer visible
  const [attrListActiveId, setAttrListActiveId] = useState(null);
  const [attrListVisible, setAttrListVisible] = useState(false);

  const {
    fetchModel,
    updateModel,
    name,
    setName,
    loading,
    attributes,
    specArr,
    appendSpec,
    updateSpec,
    removeSpec,
    removeSpecList,
    cusSpecArr,
    appendCusSpec,
    updateCusSpec,
    removeCusSpec
  } = useModel();

  // 最终提交方法
  const finalUpdateModel = async () => {
    const finalTabKey = Number(tabKey) + 1;

    await updateModel(finalTabKey);
  }

  const [onUpdate, updating] = useLoading(finalUpdateModel, () => {
    message.success(t("model.success"));

    if (id) {
      fetchModel(parseInt(id), false);
    }
  }, () => { // 错误回调，还原一下数据
    if (id) {
      fetchModel(parseInt(id), false);
    }
  });

  const labelName = [
    t("skuAttr.spec"),
    t("skuAttr.attr"),
    t("skuAttr.noSpec")
  ];

  // tab切换
  const handleTabChange = (key: any) => {
    setTabKey(key);
  }

  // ----------- 规格 -----------

  // 规格 drawer 关闭
  const handleSpecDrawerClose = () => {
    setSpecVisible(false);

    setSpecEditIndex(-1);
    setSpecValues(specObj);
    specForm.setFieldsValue(specObj);
  }

  // 规格 添加
  const handleAddSpec = () => {
    setSpecVisible(true);
  }

  // 规格点击
  const handleSpecClick = (item: any, index: number) => {
    return () => {
      setSpecVisible(true);

      const {
        name_lang,
        short_name,
        order_index
      } = item || {};

      let zh = '';
      let en = '';
      let ru = '';

      name_lang.forEach((v: any) => {
        const {
          lang,
          value
        } = v || {};

        if (lang === '0') {
          zh = value;
        }

        if (lang === '1') {
          en = value;
        }

        if (lang === '2') {
          ru = value;
        }
      });

      const values = {
        name: {
          zh,
          en,
          ru
        },
        order: order_index,
        code: short_name
      };

      setSpecEditIndex(index);
      setSpecValues(values);
      specForm.setFieldsValue(values);
    }
  }

  // 规格移除
  const handleSpecClose = (e: any, index: any) => {
    e.preventDefault();
    e.stopPropagation();

    removeSpec(index);
  }

  // 规格 drawer 重置
  const handleSpecReset = () => {
    setSpecValues(specObj);
    specForm.setFieldsValue(specObj);
  }

  // 规格 提交 or 编辑
  const handleSpecDrawerSubmit = () => {
    const values = specForm.getFieldsValue();
    const { name, code, order } = values;

    if (specEditIndex > -1) { // 编辑
      const now = specArr[specEditIndex];

      const {
        name_lang
      } = now;

      const one = name_lang[0] || {};
      const two = name_lang[1] || {};
      const three = name_lang[2] || {};

      now.name_lang = [{
        id: one.id || '',
        value: name.zh,
        lang: '0'
      }, {
        id: two.id || '',
        value: name.en,
        lang: '1'
      }, {
        id: three.id || '',
        value: name.ru,
        lang: '2'
      }]

      now.short_name = code;
      now.order_index = order;

      updateSpec(specEditIndex, now);

      handleSpecDrawerClose();

      return;
    }

    // 新增
    const newSpec: any = {};

    newSpec.name_lang = [{
      value: name.zh,
      lang: '0'
    }, {
      value: name.en,
      lang: '1'
    }, {
      value: name.ru,
      lang: '2'
    }]

    newSpec.short_name = code;
    newSpec.order_index = order;

    newSpec.list = [];

    newSpec.is_custom = 0; // 规格

    appendSpec(newSpec);

    handleSpecDrawerClose();
  }

  // ----------- 规格list -----------

  // 规格list drawer 关闭
  const handleSpecListDrawerClose = () => {
    setSpecListVisible(false);

    setSpecListEditIndex(-1);
    setSpecListValues(specListObj);
    specListForm.setFieldsValue(specListObj);
  }

  // 规格list 移除
  const handleSpecListClose = (e: any, i: any, index: any) => {
    e.preventDefault();
    e.stopPropagation();

    removeSpecList(i, index);
  }

  // 规格 list 添加
  const handleAddSpecList = (i: any) => {
    return () => {
      setSpecListEditParentIndex(i);

      setSpecListVisible(true);
    }
  }

  // 规格 list 点击
  const handleSpecListClick = (item: any, i: number, index: number) => {
    return () => {
      setSpecListEditParentIndex(i);

      setSpecListVisible(true);

      const {
        name_lang,
        short_name,
        order_index
      } = item || {};

      let zh = '';
      let en = '';
      let ru = '';

      name_lang.forEach((v: any) => {
        const {
          lang,
          value
        } = v || {};

        if (lang === '0') {
          zh = value;
        }

        if (lang === '1') {
          en = value;
        }

        if (lang === '2') {
          ru = value;
        }
      });

      const values = {
        name: {
          zh,
          en,
          ru
        },
        order: order_index,
        code: short_name
      };

      setSpecListEditIndex(index);
      setSpecListValues(values);
      specListForm.setFieldsValue(values);
    }
  }

  // 规格 list 重置
  const handleSpecListReset = () => {
    setSpecListValues(specListObj);
    specListForm.setFieldsValue(specListObj);
  }

  // 规格list 提交 or 编辑
  const handleSpecListDrawerSubmit = () => {
    const values = specListForm.getFieldsValue();
    const { name, code, order } = values;

    if (specListEditIndex > -1) { // 编辑
      const spec = specArr[specListEditParentIndex];

      const {
        list = {}
      } = spec;

      const now = list[specListEditIndex];

      const {
        name_lang
      } = now || {};

      const one = name_lang[0] || {};
      const two = name_lang[1] || {};
      const three = name_lang[2] || {};

      now.name_lang = [{
        id: one.id || '',
        value: name.zh,
        lang: '0'
      }, {
        id: two.id || '',
        value: name.en,
        lang: '1'
      }, {
        id: three.id || '',
        value: name.ru,
        lang: '2'
      }]

      now.short_name = code;
      now.order_index = order;

      updateSpec(specEditIndex, now);

      handleSpecListDrawerClose();

      return;
    }

    // 新增
    const spec = specArr[specListEditParentIndex];

    const {
      list = []
    } = spec || {};

    const newSpecList: any = {};

    newSpecList.name_lang = [{
      value: name.zh,
      lang: '0'
    }, {
      value: name.en,
      lang: '1'
    }, {
      value: name.ru,
      lang: '2'
    }]

    newSpecList.short_name = code;
    newSpecList.order_index = order;
    newSpecList.isshowpop = false;
    newSpecList.isshow = true;
    newSpecList.isaddshow = true;

    newSpecList.is_custom = 0; // 规格

    spec.list = [...list, newSpecList];

    appendSpec(spec);

    handleSpecListDrawerClose();
  }

  // ----------- 非标规格 -----------

  // 非标规格点击
  const handleCusSpecClick = (item: any, index: number) => {
    return () => {
      setCusSpecVisible(true);

      const {
        name_lang,
        short_name,
        order_index
      } = item || {};

      let zh = '';
      let en = '';
      let ru = '';

      name_lang.forEach((v: any) => {
        const {
          lang,
          value
        } = v || {};

        if (lang === '0') {
          zh = value;
        }

        if (lang === '1') {
          en = value;
        }

        if (lang === '2') {
          ru = value;
        }
      });

      const values = {
        name: {
          zh,
          en,
          ru
        },
        order: order_index,
        code: short_name
      };

      setCusSpecEditIndex(index);
      setCusSpecValues(values);
      cusSpecForm.setFieldsValue(values);
    }
  }

  // 非标规格移除
  const handleCusSpecClose = (e: any, index: any) => {
    e.preventDefault();
    e.stopPropagation();

    removeCusSpec(index);
  }

  // 非标规格 drawer 重置
  const handleCusSpecReset = () => {
    setCusSpecValues(cusSpecObj);
    cusSpecForm.setFieldsValue(cusSpecObj);
  }

  // 添加新的非标规格
  const handleAddCusSpec = () => {
    setCusSpecVisible(true);
  }

  // 非标规格drawer关闭
  const handleCusSpecDrawerClose = () => {
    setCusSpecVisible(false);

    setCusSpecEditIndex(-1);
    setCusSpecValues(cusSpecObj);
    cusSpecForm.setFieldsValue(cusSpecObj);
  }

  // 非标规格 drawer 提交 or 编辑
  const handleCusSpecDrawerSubmit = () => {
    const values = cusSpecForm.getFieldsValue();
    const { name, code, order } = values;

    if (cusSpecEditIndex > -1) { // 编辑
      const now = cusSpecArr[cusSpecEditIndex];

      const {
        name_lang
      } = now;

      const one = name_lang[0] || {};
      const two = name_lang[1] || {};
      const three = name_lang[2] || {};

      now.name_lang = [{
        id: one.id || '',
        value: name.zh,
        lang: '0'
      }, {
        id: two.id || '',
        value: name.en,
        lang: '1'
      }, {
        id: three.id || '',
        value: name.ru,
        lang: '2'
      }]

      now.short_name = code;
      now.order_index = order;

      updateCusSpec(cusSpecEditIndex, now);

      handleCusSpecDrawerClose();

      return;
    }

    // 新增
    const newCusSpec: any = {};

    newCusSpec.name_lang = [{
      value: name.zh,
      lang: '0'
    }, {
      value: name.en,
      lang: '1'
    }, {
      value: name.ru,
      lang: '2'
    }]

    newCusSpec.short_name = code;
    newCusSpec.order_index = order;
    newCusSpec.list = [];
    newCusSpec.is_custom = 1; // 非标参数

    appendCusSpec(newCusSpec);

    handleCusSpecDrawerClose();
  }

  useEffect(() => {
    if (id) {
      fetchModel(parseInt(id), true, (res: any) => {
        if (res === null) { // 无数据
          setEmptyFlag(true);

          return;
        }

        const url = window.location.href;

        const param = getUrlParam(url);

        const {
          type,
          specId,
          attrId
        } = param || {};

        if (type === '0') { // 默认规格
          if (specId) { // 添加规格子类
            let index: any = -1;

            const {
              specArr = []
            } = res || {};

            specArr.forEach((s: any, i: any) => {
              const {
                id
              } = s || {};

              if (id === Number(specId)) {
                index = i;
              }
            });

            if (index > -1) { // 有值
              setSpecListEditParentIndex(index);

              setSpecListVisible(true);
            }
          } else { // 添加规格
            setSpecVisible(true);
          }
        }

        if (type === '1') { // 默认属性
          setTabKey('1'); // tab切换到属性

          if (attrId) { // 添加属性子类
            setAttrListActiveId(attrId);

            setAttrListVisible(true);
          } else { // 添加属性
            setAttrVisible(true);
          }
        }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // 规格 render
  const renderSkuContainer = () => {
    return (
      <div className="amtc-tab-content amtc-tab-content-sku">
        {
          specArr.length > 0 ? specArr.map((v: any, i: number) => {
            const {
              id,
              list = [],
              name_lang = []
            } = v || {};

            let nameLang = '';

            name_lang.forEach((n: any) => {
              const {
                lang,
                value
              } = n || {};

              const numLang = Number(lang);

              if (numLang === nowLang) {
                nameLang = value;
              }
            });

            let finalText = '';

            if (id) {
              finalText = `${id}: ${nameLang}`;
            } else { // 一般这个是新增的
              finalText = nameLang;
            }

            return (
              <div
                key={`amtc-tab-content-spec-list-${id}`}
                className="amtc-tcs-list"
              >
                <div
                  className="amtc-tcsl-tag"
                  onClick={handleSpecClick(v, i)}
                >
                  {finalText}
                  <CloseOutlined
                    onClick={e => handleSpecClose(e, i)}
                  />
                </div>
                <div className="amtc-tcs-list-driver">
                  :
                </div>
                {
                  list.length > 0 ? list.map((t: any, index: number) => {
                    const {
                      id,
                      name_lang = []
                    } = t || {};

                    let nameLang = '';

                    name_lang.forEach((n: any) => {
                      const {
                        lang,
                        value
                      } = n || {};

                      const numLang = Number(lang);

                      if (numLang === nowLang) {
                        nameLang = value;
                      }
                    });

                    let finalText = '';

                    if (id) {
                      finalText = `${id}: ${nameLang}`;
                    } else { // 一般这个是新增的
                      finalText = nameLang;
                    }
                    return (
                      <div 
                        key={`amtc-tcsl-more-tag-${index}-${id}`}
                        className="amtc-tcsl-more-tag"
                        onClick={handleSpecListClick(t, i, index)}
                      >
                        {finalText}
                        <CloseOutlined
                          onClick={e => handleSpecListClose(e, i, index)}
                        />
                      </div>
                    )
                  }) : null
                }
                <Tooltip
                  title={t("model.addTextAtt.button")}
                >
                  <Button
                    type="text"
                    icon={<PlusCircleOutlined />}
                    onClick={handleAddSpecList(i)}
                  />
                </Tooltip>
              </div>
            )
          }) : null
        }
        <Button
          type="dashed"
          className="amtc-add-spec-btn"
          icon={<PlusOutlined/>}
          onClick={handleAddSpec}
        >
          {t("model.addSpc.button")}
        </Button>
      </div>
    )
  }

  // 属性 render
  const renderAttrContainer = () => {
    return (
      <div className="amtc-tab-content amtc-tab-content-attr">
        {
          attributes.map((item, index) => {
            return (
              <AttributeChunk
                defaultDrawerVisible={attrListVisible}
                attrListActiveId={attrListActiveId}
                id={item.id || 0}
                key={index}
                rowIndex={index}
                name={item.name}
                type={item.type}
                order={item.order}
                items={item.list || []}
                classifyId={item.classifyId}
                isLast={index === attributes.length - 1}
              />
            )
          })
        }
        <CreateAttribute
          defaultVisible={attrVisible}
        />
      </div>
    );
  }

  // 非标规格 render
  const renderCusSpecContainer = () => {
    return (
      <div className='amtc-tab-content amtc-tab-content-cus-spec'>
        {
          cusSpecArr.length > 0 ? cusSpecArr.map((v: any, i: number) => {
            const {
              id,
              name_lang = []
            } = v || {};

            let nameLang = '';

            name_lang.forEach((n: any) => {
              const {
                lang,
                value
              } = n || {};

              const numLang = Number(lang);

              if (numLang === nowLang) {
                nameLang = value;
              }
            });

            let finalText = '';

            if (id) {
              finalText = `${id}: ${nameLang}`;
            } else { // 一般这个是新增的
              finalText = nameLang;
            }

            return (
              <div
                key={`amtc-tab-content-cus-spec-list-${id}`}
                className="amtc-tcc-list"
              >
                <div
                  className="amtc-tccl-tag"
                  onClick={handleCusSpecClick(v, i)}
                >
                  {finalText}
                  <CloseOutlined
                    onClick={e => handleCusSpecClose(e, i)}
                  />
                </div>
              </div>
            )
          }) : null
        }
        <Button
          type="dashed"
          className="amtc-add-cus-spec-btn"
          icon={<PlusOutlined/>}
          onClick={handleAddCusSpec}
        >
          {t("model.addCusSpec.button")}
        </Button>
      </div>
    );
  }

  // tab 渲染
  const renderTab = (i: any) => {
    if (i === 0) {
      return renderSkuContainer();
    }

    if (i === 1) {
      return renderAttrContainer();
    }

    if (i === 2) {
      return renderCusSpecContainer();
    }
  }

  if (loading) {
    return (
      <BaseLayout>
        <PageHeader title="Model" style={{ padding: "16px 0" }} />
        <Card>
          <div
            style={{
              minHeight: "70vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Spin />
          </div>
        </Card>
      </BaseLayout>
    );
  }

  if (emptyFlag) {
    return (
      <BaseLayout>
        <PageHeader title="Model" style={{ padding: "16px 0" }} />
        <Card>
          <div
            style={{
              minHeight: "70vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Empty description={t("skuAttr.noData")} />
          </div>
        </Card>
      </BaseLayout>
    );
  }

  const specFormValues: any = specValues || {
    name: {
      zh: "",
      en: "",
      ru: "",
    },
    order: 100,
    code: ''
  };

  const specListFormValues: any = specListValues || {
    name: {
      zh: "",
      en: "",
      ru: "",
    },
    order: 100,
    code: ''
  };

  const cusSpecFormValues: any = cusSpecValues || {
    name: {
      zh: "",
      en: "",
      ru: "",
    },
    order: 100,
    code: ''
  };

  return (
    <BaseLayout>
      <PageHeader
        title={<EditableText value={name} onFinish={setName} />}
        style={{ padding: "16px 0" }}
        extra={
          <Space>
            <Button
              type="primary"
              onClick={onUpdate}
              loading={updating}
            >
              {t("model.submit")}
            </Button>
          </Space>
        }
      />
      <Card
        className="aiko-model-tab-card"
        bodyStyle={{ paddingTop: 4, paddingLeft: 2, paddingRight: 2, paddingBottom: 4 }}
      >
        <div className="amtc-tab">
          <Tabs
            activeKey={tabKey}
            onChange={handleTabChange}
            type="card"
            items={new Array(3).fill(null).map((_, i) => {
              const label = labelName[i];

              return {
                label: label,
                key: `${i}`,
                children: renderTab(i),
              };
            })}
          />
        </div>
      </Card>
      {
        specVisible ? (
          <Drawer
            title={t("model.addSpc.title")}
            visible={specVisible}
            onClose={handleSpecDrawerClose}
            destroyOnClose
            extra={
              specEditIndex > -1 ? (
                <Space>
                  <Button onClick={handleSpecReset}>
                    {t("model.editAttr.reset")}
                  </Button>
                  <Button
                    type="primary"
                    onClick={handleSpecDrawerSubmit}
                  >
                    {t("model.editAttr.submit")}
                  </Button>
                </Space>
              ) : (
                <Button
                  type="primary"
                  onClick={handleSpecDrawerSubmit}
                >
                  {t("model.addAttr.submit")}
                </Button>
              )
            }
          >
            <div className="aiko-model-spec-drawer-container">
              <Form
                layout="vertical"
                form={specForm}
                initialValues={specFormValues}
              >
                <Form.Item label={t("model.attrForm.name")} name="name" required>
                  <InputName />
                </Form.Item>
                <Form.Item label={t("model.addCusSpec.code")} name="code" required>
                  <Input />
                </Form.Item>
                <Form.Item label={t("model.attrForm.sort")} name="order" required>
                  <InputNumber min={0} precision={0} />
                </Form.Item>
              </Form>
            </div>
          </Drawer>
        ) : null
      }
      {
        specListVisible ? (
          <Drawer
            title={t("model.addSpc.title")}
            visible={specListVisible}
            onClose={handleSpecListDrawerClose}
            destroyOnClose
            extra={
              specListEditIndex > -1 ? (
                <Space>
                  <Button onClick={handleSpecListReset}>
                    {t("model.editAttr.reset")}
                  </Button>
                  <Button
                    type="primary"
                    onClick={handleSpecListDrawerSubmit}
                  >
                    {t("model.editAttr.submit")}
                  </Button>
                </Space>
              ) : (
                <Button
                  type="primary"
                  onClick={handleSpecListDrawerSubmit}
                >
                  {t("model.addAttr.submit")}
                </Button>
              )
            }
          >
            <div className="aiko-model-spec-list-drawer-container">
              <Form
                layout="vertical"
                form={specListForm}
                initialValues={specListFormValues}
              >
                <Form.Item label={t("model.attrForm.name")} name="name" required>
                  <InputName />
                </Form.Item>
                <Form.Item label={t("model.addCusSpec.codeValue")} name="code" required>
                  <Input />
                </Form.Item>
                <Form.Item label={t("model.attrForm.sort")} name="order" required>
                  <InputNumber min={0} precision={0} />
                </Form.Item>
              </Form>
            </div>
          </Drawer>
        ) : null
      }
      {
        cusSpecVisible ? (
          <Drawer
            title={t("model.addCusSpec.title")}
            visible={cusSpecVisible}
            onClose={handleCusSpecDrawerClose}
            destroyOnClose
            extra={
              cusSpecEditIndex > -1 ? (
                <Space>
                  <Button onClick={handleCusSpecReset}>
                    {t("model.editAttr.reset")}
                  </Button>
                  <Button
                    type="primary"
                    onClick={handleCusSpecDrawerSubmit}
                  >
                    {t("model.editAttr.submit")}
                  </Button>
                </Space>
              ) : (
                <Button
                  type="primary"
                  onClick={handleCusSpecDrawerSubmit}
                >
                  {t("model.addAttr.submit")}
                </Button>
              )
            }
          >
            <div className="aiko-model-cus-spec-drawer-container">
              <Form
                layout="vertical"
                form={cusSpecForm}
                initialValues={cusSpecFormValues}
              >
                <Form.Item label={t("model.attrForm.name")} name="name" required>
                  <InputName />
                </Form.Item>
                <Form.Item label={t("model.addCusSpec.code")} name="code" required>
                  <Input />
                </Form.Item>
                <Form.Item label={t("model.attrForm.sort")} name="order" required>
                  <InputNumber min={0} precision={0} />
                </Form.Item>
              </Form>
            </div>
          </Drawer>
        ) : null
      }
    </BaseLayout>
  );
};

export default Model;
