import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { Button, Drawer, Form } from "antd";

import { PlusOutlined } from "@ant-design/icons";

import AttributeTypeForm from "./AttributeTypeForm";

import useDrawer from "../../hooks/useDrawer";

import useModel from "../../stores/model";

const { useForm } = Form;

const CreateAttribute = ({
  defaultVisible = false
}: any) => {
  const [form] = useForm();

  const [first, setFirst] = useState(true);

  const { t } = useTranslation();

  const { appendAttribute } = useModel();

  const submitHook = () => {
    const values = form.getFieldsValue();

    const {
      name,
      type,
      order
    } = values;

    const newAttribute = {
      type,
      order,
      name: [
        { value: name.zh, lang: "0" },
        { value: name.en, lang: "1" },
        { value: name.ru, lang: "2" }
      ],
      list: [],
    };

    appendAttribute(newAttribute);
  };

  const {
    visible,
    show,
    hide,
    onSubmit,
  } = useDrawer(submitHook);

  useEffect(() => {
    if (first && defaultVisible) {
      show();

      setFirst(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultVisible]);

  return (
    <div style={{ marginTop: 10 }}>
      <Button
        type="dashed"
        icon={<PlusOutlined />}
        onClick={show}
      >
        {t("model.addAttr.button")}
      </Button>
      <Drawer
        title={t("model.addAttr.title")}
        visible={visible}
        onClose={hide}
        destroyOnClose
        extra={
          <Button
            type="primary"
            onClick={onSubmit}
          >
            {t("model.addAttr.submit")}
          </Button>
        }
      >
        <AttributeTypeForm form={form} />
      </Drawer>
    </div>
  );
};

export default CreateAttribute;
