import { useEffect } from "react";
import { PageHeader } from "antd";
import useStorage from "../../stores/storage";
import BaseLayout from "../../components/BaseLayout";
import FileUpload from "./FileToolbox/FileUpload";
import NewFolder from "./FileToolbox/NewFolder";
import FileCard from './FileCard'
import { useTranslation } from "react-i18next";

const FileManagerBody = () => {
  const { t } = useTranslation();

  return (
    <BaseLayout title={t("route.fileManager")}>
      <PageHeader
        title={t("route.fileManager")}
        style={{ padding: "16px 0" }}
        extra={[<NewFolder key="new" />, <FileUpload key="upload" />]}
      />
      <FileCard />
    </BaseLayout>
  );
};

const FileManager = () => {
  const { currentRef, resetAll } = useStorage();

  useEffect(() => {
    resetAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <FileManagerBody key={currentRef} />;
};

export default FileManager;
