import React, { useEffect, useState } from "react";
import { List, Row, Checkbox } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import {
  SortableHandle,
  SortableElement,
  SortableContainer,
} from "react-sortable-hoc";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { arrayMoveImmutable } from "array-move";

const DragHandle: any = SortableHandle(() => (
  <MenuOutlined style={{ cursor: "grab", color: "#999" }} />
));

interface SortableItemProps {
  value: CheckListItem;
  onChange: (key: string, checked: boolean) => void;
}

const SortableItem: any = SortableElement(
  ({ value, onChange }: SortableItemProps) => {
    const handleChange = (e: CheckboxChangeEvent) => {
      const checked = e.target.checked;
      onChange(value.key, checked);
    };

    return (
      <List.Item style={{ zIndex: 1000, padding: "8px 0" }}>
        <Row justify="space-between" style={{ width: "100%" }}>
          <Checkbox checked={value.checked} onChange={handleChange}>
            {value.name}
          </Checkbox>
          <DragHandle />
        </Row>
      </List.Item>
    );
  }
);

const SortableBody: any = SortableContainer(
  ({ children }: { children: React.ReactNode }) => {
    return <div>{children}</div>;
  }
);

interface CheckListItem {
  key: string;
  name: string;
  checked?: boolean;
}

interface SortableCheckListProps {
  value?: CheckListItem[];
  onChange?: (value: CheckListItem[]) => void;
}

const SortableCheckList = ({ value, onChange }: SortableCheckListProps) => {
  const [selected, setSelected] = useState<CheckListItem[]>(value || []);

  useEffect(() => {
    if (value !== selected && value !== undefined) {
      setSelected(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(value)]);

  const triggerChange = (changedValue: CheckListItem[]) => {
    onChange?.(changedValue);
  };

  const handleCheck = (key: string, checked: boolean) => {
    const newSelected = selected.map((item) => {
      if (item.key === key) {
        return {
          ...item,
          checked,
        };
      }
      return item;
    });

    if (value === undefined) setSelected(newSelected);
    triggerChange(newSelected);
  };

  const onSortEnd = ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number;
    newIndex: number;
  }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(selected, oldIndex, newIndex);

      if (value === undefined) setSelected(newData);
      triggerChange(newData);
    }
  };

  return (
    <SortableBody onSortEnd={onSortEnd} useDragHandle>
      <List
        split={false}
        dataSource={value || selected}
        renderItem={(item, index) => (
          <SortableItem
            key={item.key}
            index={index}
            value={item}
            onChange={handleCheck}
          />
        )}
      />
    </SortableBody>
  );
};

export default SortableCheckList;
