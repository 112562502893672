

import {
  Tag,
  Spin,
  Tabs,
  Card,
  Empty,
  Select,
  Button,
  Tooltip,
  Popover,
  Checkbox,
  PageHeader,
  notification
} from 'antd';

import _ from 'lodash';

import {
  PlusOutlined,
  EditOutlined,
  CloseOutlined,
  PlusCircleOutlined
} from '@ant-design/icons';

import { useTranslation } from 'react-i18next';

import { useParams } from 'react-router-dom';

import BaseLayout from '../../components/BaseLayout';

import { useEffect, useState } from 'react';

import spuServices from '../../services/spu';

import { addWindowsClazz } from '../../utils';

import './index.css';

const { Option } = Select;

const TAG_BG_COLOR = '#1890ff';

const SkuAttr = () => {
  const { t } = useTranslation();

  const labelName = [
    t("skuAttr.spec"),
    t("skuAttr.attr"),
    t("skuAttr.noSpec")
  ];

  // 强制刷新一下
  const [index, setIndex] = useState(0);

  // 获取基础数据loading
  const [loading, setLoading] = useState(false);

  // 规格提交loading
  const [skuSubmitLoading, setSkuSubmitLoading] = useState(false);

  // 属性提交loading
  const [attrSubmitLoading, setAttrSubmitLoading] = useState(false);

  // 非标规格提交loading
  const [cusSpecSubmitLoading, setCusSpecSubmitLoading] = useState(false);

  // spu类似loading
  const [sameSpuLoading, setSameSpuLoading] = useState(false);

  // tab key
  const [tabKey, setTabKey] = useState('0');

  // valid tab key
  const [validTabKey, setValidTabKey] = useState('1'); // 默认是有效的

  const infoObj: any = {
    spu: ''
  };

  // spuInfo
  const [info, setInfo] = useState(infoObj);

  // 模型value
  const [model, setModel] = useState();

  // 模型list
  const [modelList, setModelList] = useState([]);

  // spu类型edit
  const [sameSpuEdit, setSameSpuEdit] = useState(false);

  const sameSpuObj: any = {
    nameLang: ''
  };

  // spu类似value
  const [sameSpu, setSameSpu] = useState(sameSpuObj);
  
  // spu的可选规格
  const [spuCanSpec, setSpuCanSpec] = useState([])

  // spu的已选规格
  const [spuSelectSpec, setSpuSelectSpec] = useState([]);

  const commonArray: any = [];

  // spu的规格的已选sku
  const [spuHave, setSpuHave] = useState(commonArray);

  // spu的规格的已选sku缓存数据 【已选择 即有效的，后续选择的有效的所有数据都在这】
  const [spuHaveInfos, setSpuHaveInfos] = useState(commonArray);

  // spu的生成的table的sku数据 【未选择 即无效的】
  const [spuTempNoSku, setSpuTempNoSku] = useState(commonArray);

  const spuAttrFilterObj: any = {};

  // 表格属性筛选
  const [spuAttrFilter, setSpuAttrFilter] = useState(spuAttrFilterObj);

  // spu的可选属性
  const [spuCanAttr, setSpuCanAttr] = useState([]);

  // spu的已选属性
  const [spuSelectAttr, setSpuSelectAttr] = useState([]);

  // spu类似列表  
  const [sameSpuList, setSameSpuList] = useState([]);

  // 可选非标规格list
  const [canCusSpecList, setCanCusSpecList] = useState([]);

  // 已选非标规格list
  const [selectCusSpecList, setSelectCusSpecList] = useState([]);

  // url id
  const { id } = useParams();

  // 获取基础列表数据
  const getBaseListData = async (value?: any, sameId?: any) => {
    setLoading(true);

    let params: any = {
      modelID: -1 // 默认-1
    };

    if (value) {
      params = {
        modelID: value
      };
    } else {
      if (model) {
        params = {
          modelID: model
        };
      }
    }

    // 类似spu模型 还原一下
    setSameSpuLoading(false);
    setSameSpuList([]);
    setSameSpuEdit(false);

    const res = await spuServices.getSkuListBySpu(id, params);

    const {
      data: rData = {}
    } = res || {};

    const data = rData.response;

    const {
      attr,
      spuInfo,
      spuModel: spuModelList,
      cusSpec = [],
      sameSpu: dSameSpu,
      spec,
      sku
    } = data || {};

    const finalModelList = spuModelList.map((v: any) => {
      const {
        id,
        name
      } = v || {};

      return {
        value: id,
        label: name
      }
    });

    const {
      spuModel
    } = spuInfo || {};

    setInfo(spuInfo);

    if (!value) {
      setModel(spuModel);
    }

    setModelList(finalModelList);

    // 已选中的sku
    setSpuHave(sku);

    // 规格
    let canSpec: any = [];
    let selectSpec: any = [];

    spec.forEach((v: any) => {
      const {
        select = []
      } = v || {};

      if (select.length > 0) { // 已选
        selectSpec.push(v);
      } else { // 可选
        canSpec.push(v);
      }
    });

    setSpuCanSpec(canSpec);
    setSpuSelectSpec(selectSpec);

    // 属性
    let canAttr: any = [];
    let selectAttr: any = [];

    attr.forEach((v: any) => {
      const {
        select
      } = v || {};

      if (select === 0) { // 可选
        canAttr.push(v);
      } else { // 已选 [这个值是item里面的id]
        selectAttr.push(v);
      }
    });

    setSpuCanAttr(canAttr);
    setSpuSelectAttr(selectAttr);

    // 非标规格
    let canCusSpec: any = [];
    let selectCusSpec: any = [];

    cusSpec.forEach((v: any) => {
      const {
        selected
      } = v || {};

      if (selected === 1) { // 已选
        selectCusSpec.push(v);
      } else { // 可选
        canCusSpec.push(v);
      }
    });

    setCanCusSpecList(canCusSpec);
    setSelectCusSpecList(selectCusSpec);

    // 类似spu模型
    if (!sameId) {
      if (dSameSpu && Object.prototype.toString.apply(dSameSpu) === '[object Object]') {
        setSameSpu(dSameSpu);
      }
    } else {
      finalSpuSameChange(sameId, {
        spuCanSpec: canSpec,
        spuSelectSpec: selectSpec,
        spuCanAttr: canAttr,
        spuSelectAttr: selectAttr
      });
    }

    setLoading(false);
  }

  // ------------- 笛卡尔积表格相关 start -------------

  // 多个做笛卡尔积
  const multiCartesian = (data: any) => {
    const len = data.length;

    if (len === 0) {
      return [];
    } else if (len === 1) {
      return data[0];
    } else {
      let r = data[0];

      for (let i = 1; i < len; i++) {
        r = Cartesian(r, data[i]);
      }

      return r;
    }

    // 笛卡尔积
    function Cartesian(a: any, b: any) {
      const ret = [];

      for (let i = 0; i < a.length; i++) {
        for (let j = 0; j < b.length; j++) {
          ret.push(`${a[i]}_${b[j]}`);
        }
      }

      return ret;
    }
  }

  // 获取规格的笛卡尔积，得到sku列表
  const setSkuTable = () => {
    const spec: any = [...spuSelectSpec]; // 当前已选规格

    const allSpecSelect: any = []; // 选中的所有的具体规格，用来做笛卡尔积的基数

    const items: any = {}; // 选中的所有规格对象

    spec.forEach((v: any) => {
      const {
        item,
        select
      } = v || {};

      if (select && select.length !== 0) {
        allSpecSelect.push(select);
      }

      item.forEach((vi: any) => {
        const {
          id
        } = vi || {};

        items[id] = vi;
      });
    });

    const allSku = multiCartesian(allSpecSelect);

    getTableDataByDecare(allSku, items);
  }

  // 根据笛卡尔积结果，组装表格数据
  const getTableDataByDecare = (allSku: any, items: any) => {
    const noTemps: any = [];
    const temps: any = [];

    allSku.forEach((v: any) => {
      const strV = `${v}`;

      const keyArr: any = strV.split('_');

      // 重新排序key 按照升序排序
      const tempArr: any = strV.split('_').sort((a: any, b: any) => {
        return a - b;
      });

      const tempK: any = tempArr.join('_');

      const temp: any = {
        key: tempK,
        keys: '',
        key_name: '',
        sku: `${info.spu}_`
      };

      keyArr.forEach((v: any) => {
        const item = items[v];

        const {
          spec_id,
          nameLang,
          short_name: shortName
        } = item || {};

        const specItems: any = spuSelectSpec.filter((s: any) => s.id === spec_id);
        const specItem: any = specItems[0] || {};

        const {
          nameLang: sNameLange,
          short_name: sShortName
        } = specItem || {};

        // 连接中文key_name
        temp.key_name += `${sNameLange}:${nameLang}/`;

        // 生成sku编码
        temp.sku += `${sShortName}:${shortName}/`;

        // 生成当前列的值
        temp[`${spec_id}`] = nameLang;

        temp.keys = temp.keys ? `${temp.keys}_${spec_id}` : spec_id;

        // // 生成当前列的值的对应id
        temp[`${spec_id}_specItemId`] = v;
      });

      temp.key_name = temp.key_name.substring(0, temp.key_name.length - 1);
      temp.sku = temp.sku.substring(0, temp.sku.length - 1);

      const flag = spuHave && spuHave.indexOf(tempK);

      if (flag < 0) {
        noTemps.push(temp);
      } else {
        temps.push(temp);
      }
    });

    const cacheInfos = _.uniqBy([...spuHaveInfos, ...temps], 'key');

    setSpuHaveInfos(cacheInfos);

    setSpuTempNoSku(noTemps);
  }

  // ------------- end -------------

  // 刷新
  const handleRefreshClick = () => {
    getBaseListData();
  }

  // tab切换
  const handleTabChange = (key: any) => {
    setTabKey(key);
  }

  // 模型 select 选择
  const handleModelChange = (value: any) => {
    setModel(value);

    getBaseListData(value);
  }

  // 类似spu模型 select 搜索
  const handleSpuSameSearch = async (newValue: any) => {
    if (newValue) {
      setSameSpuLoading(true);

      setSameSpuList([]);

      const res = await spuServices.postSameSpuList({
        spuID: id,
        key: newValue
      });

      const {
        data: rData = {}
      } = res || {};
  
      const data = rData.response || [];

      setSameSpuLoading(false);
      setSameSpuList(data);
    } else {
      setSameSpuList([]);
    }
  }

  // 类似spu模型 select 选择
  const handleSpuSameChange = async (value: any) => {
    const filters = sameSpuList.filter((v: any) => v.id === value);
    const obj: any = filters[0] || {};

    const {
      id,
      modelID,
      name_lang
    } = obj || {};

    setSameSpu({
      ...obj,
      nameLang: name_lang
    });

    setSameSpuEdit(false);

    // 调用一下详情
    setLoading(true);

    // 【如果模型modelID 变了，需要先调用基础数据】
    if (model !== modelID) {
      setModel(modelID);

      getBaseListData(modelID, id);
    } else {
      finalSpuSameChange(id);
    }
  }

  // 类似模型spu select选择 final
  const finalSpuSameChange = async (id: any, params?: any) => {
    let allSpec: any = [...spuCanSpec, ...spuSelectSpec];
    let allAttr: any = [...spuCanAttr, ...spuSelectAttr];

    if (params) {
      const {
        spuCanSpec,
        spuSelectSpec,
        spuCanAttr,
        spuSelectAttr
      } = params || {};

      allSpec = [...spuCanSpec, ...spuSelectSpec];
      allAttr = [...spuCanAttr, ...spuSelectAttr];
    }

    const res = await spuServices.postSameSpuInfo({
      sameSpu: id
    });

    const {
      data: rData = {}
    } = res || {};

    const data = rData.response;

    const {
      attr,
      sku,
      spec
    } = data || {};

    setSpuHave(sku);

    // 相同规格
    let canSpec: any = [];
    let selectSpec: any = [];

    allSpec.forEach((v: any) => {
      const {
        id
      } = v;

      if (spec.hasOwnProperty(id)) { // 已选规格
        v['select'] = spec[id];

        selectSpec.push(v);
      } else { // 未选规格
        v['select'] = [];

        canSpec.push(v);
      }
    });

    setSpuCanSpec(canSpec);
    setSpuSelectSpec(selectSpec);

    // 相同属性
    let canAttr: any = [];
    let selectAttr: any = [];

    allAttr.forEach((v: any) => {
      const {
        id
      } = v || {};

      if (attr.hasOwnProperty(id)) { // 已选属性
        const item = attr[id] || {};
        v['select'] = item['attr_item_id'];

        selectAttr.push(v);
      } else { // 未选属性
        v['select'] = 0;

        canAttr.push(v);
      }
    });

    setSpuCanAttr(canAttr);
    setSpuSelectAttr(selectAttr);

    setLoading(false);
  }

  // 调用类似SPU模型切换显示
  const handleSpuSameEditClick = () => {
    const finalEdit = !sameSpuEdit;

    setSameSpuEdit(finalEdit);
  }

  // 规格可选规格点击
  const handleSpuCanSpecClick = (item: any) => {
    return () => {
      const {
        id
      } = item || {};

      let canSpec: any = [];
      let selectSpec: any = [];

      item.select = null;

      canSpec = spuCanSpec.filter((v: any) => v.id !== id);

      selectSpec = [...spuSelectSpec, item];

      setSpuCanSpec(canSpec);
      setSpuSelectSpec(selectSpec);
    };
  }

  // 规格已选规格取消
  const handleSpuSelectSpecClick = (e: any, item: any) => {
    e.preventDefault();

    const {
      id
    } = item || {};

    let canSpec: any = [];
    let selectSpec: any = [];

    item.select = [];

    canSpec = [...spuCanSpec, item];

    selectSpec = spuSelectSpec.filter((v: any) => v.id !== id);

    canSpec.forEach((v: any) => {
      v.select = [];
    });

    setSpuCanSpec(canSpec);
    setSpuSelectSpec(selectSpec);
  }

  // 多选带边框按钮变色
  const handleCheckList = (val: any, index: number) => {
    const selectSpec: any = [...spuSelectSpec];

    selectSpec[index].select = val;

    setSpuSelectSpec(selectSpec);
  }

  // 属性可选规格点击
  const handleSpuCanAttrClick = (item: any) => {
    return () => {
      const {
        id
      } = item || {};

      let canAttr: any = [];
      let selectAttr: any = [];

      item.select = null;

      canAttr = spuCanAttr.filter((v: any) => v.id !== id);

      selectAttr = [...spuSelectAttr, item];

      setSpuCanAttr(canAttr);
      setSpuSelectAttr(selectAttr);
    };
  }

  // 属性已选规格取消
  const handleSpuSelectAttrClick = (item: any) => {
    return () => {
      const {
        id
      } = item || {};

      let canAttr: any = [];
      let selectAttr: any = [];

      item.select = null;

      canAttr = [...spuCanAttr, item];

      selectAttr = spuSelectAttr.filter((v: any) => v.id !== id);

      setSpuCanAttr(canAttr);
      setSpuSelectAttr(selectAttr);
    };
  }

  // 属性已选规格select选择
  const handleSkuAttrChange = (value: any, id: any) => {
    const attrs = spuSelectAttr;

    const filters = attrs.filter((v: any) => v.id === id);
    const item: any = filters[0];

    if (item) {
      item.select = value;
    }

    setSpuSelectAttr(attrs);
    setIndex(index + 1);
  }

  // 非标规格可选规格点击
  const handleCanCusSpecClick = (item: any) => {
    return () => {
      const {
        id
      } = item || {};

      let canCusSpec: any = [];
      let selectCusSpec: any = [];

      item.selected = 1; // 变成已选

      canCusSpec = canCusSpecList.filter((v: any) => v.id !== id);

      selectCusSpec = [...selectCusSpecList, item];

      setCanCusSpecList(canCusSpec);
      setSelectCusSpecList(selectCusSpec);
    };
  }

  // 非标规格已选规格取消
  const handleSelectCusSpecClick = (item: any) => {
    return () => {
      const {
        id
      } = item || {};

      let canCusSpec: any = [];
      let selectCusSpec: any = [];

      item.selected = 0; // 变成可选

      canCusSpec = [...canCusSpecList, item];

      selectCusSpec = selectCusSpecList.filter((v: any) => v.id !== id);

      setCanCusSpecList(canCusSpec);
      setSelectCusSpecList(selectCusSpec);
    };
  }

  // 规格提交 【如果modalID 变了，规格和属性都得提交】
  const handleSkuSubmitClick = async () => {
    setSkuSubmitLoading(true);

    const {
      id: sameSpuID
    } = sameSpu || {};

    if (info.spuModel !== model) {
      let finalAttr: any = [];

      for (let i in spuSelectAttr) {
        const item = spuSelectAttr[i];

        const {
          id: itemId,
          select,
          nameLang
        } = item || {};

        if (!select) {
          notification.error({
            message: `${nameLang}: ${t("skuAttr.attrNameError")}`
          });

          return;
        }

        finalAttr.push({
          attr_item_id: select,
          attr_id: itemId,
          spu_id: Number(id)
        });
      }

      await spuServices.saveSkuListBySpu({
        saveType: 2, // 规格和属性， modal变化的时候
        spuID: Number(id),
        modelID: model,
        sameSpuID,
        sku: spuHave,
        attr: finalAttr
      });
    } else {
      await spuServices.saveSkuListBySpu({
        saveType: 0, // 规格
        spuID: Number(id),
        modelID: model,
        sameSpuID,
        sku: spuHave
      });
    }

    notification.success({
      message: t("skuAttr.submitOk")
    });

    setSkuSubmitLoading(false);
  }

  // 属性提交【如果modalID 变了，规格和属性都得提交】
  const handleAttrSubmitClick = async () => {
    let finalAttr: any = [];

    for (let i in spuSelectAttr) {
      const item = spuSelectAttr[i];

      const {
        id: itemId,
        select,
        nameLang
      } = item || {};

      if (!select) {
        notification.error({
          message: `${nameLang}: ${t("skuAttr.attrNameError")}`
        });

        return;
      }

      finalAttr.push({
        attr_item_id: select,
        attr_id: itemId,
        spu_id: Number(id)
      });
    }

    setAttrSubmitLoading(true);

    const {
      id: sameSpuID
    } = sameSpu || {};

    if (info.spuModel !== model) {
      await spuServices.saveSkuListBySpu({
        saveType: 2, // 规格和属性， modal变化的时候
        spuID: Number(id),
        modelID: model,
        sameSpuID,
        sku: spuHave,
        attr: finalAttr
      });
    } else {
      await spuServices.saveSkuListBySpu({
        saveType: 1, // 属性
        spuID: Number(id),
        modelID: model,
        sameSpuID,
        attr: finalAttr
      });
    }

    notification.success({
      message: t("skuAttr.submitOk")
    });

    setAttrSubmitLoading(false);
  }

  // 非标规格提交
  const handleCusSpecSubmitClick = async () => {
    const {
      id: sameSpuID
    } = sameSpu || {};

    const cusSpecIds = selectCusSpecList.map((v: any) => v.id);

    setCusSpecSubmitLoading(true);

    await spuServices.saveSkuCusSpecBySpu({
      spuID: Number(id),
      modelID: model,
      sameSpuID,
      cusSpec: cusSpecIds
    });

    notification.success({
      message: t("skuAttr.submitOk")
    });

    setCusSpecSubmitLoading(false);
  }

  // 无效/有效tab切换
  const handleValidTabClick = (tab: any) => {
    return () => {
      setValidTabKey(tab);
    };
  }

  // 属性表格 - 表头筛选重置
  const handleResetFilterClick = () => {
    setSpuAttrFilter({});
  }

  // 属性表格 - 表头全部无效
  const handleAllInvalidClick = () => {
    setSpuHave([]);
  }

  // 属性表格 - 表头全部有效
  const handleAllValidClick = () => {
    const arr = spuHaveInfos.filter((v: any) => {
      const {
        key
      } = v || {};

      if (spuHave && spuHave.indexOf(key) > -1) {
        return true;
      }

      return false;
    });

    const allSku = _.uniqBy([...arr, ...spuTempNoSku], 'key');

    const allSkuKeys = allSku.map((v: any) => {
      const {
        key
      } = v;

      return key;
    });

    setSpuHave(allSkuKeys);
  }

  // 属性表格 - 表格无效
  const handleInvalidClick = (item: any) => {
    return () => {
      const {
        key
      } = item || {};

      const finalSpuHave = spuHave.filter((v: any) => v !== key);

      setSpuHave(finalSpuHave);
    }
  }

  // 属性表格 - 表格有效
  const handleValidClick = (item: any) => {
    return () => {
      const {
        key
      } = item || {};

      const finalSpuHave = [...spuHave];

      finalSpuHave.push(key)

      setSpuHave(finalSpuHave);
    }
  }

  // 属性表格 - 表头筛选
  const handleTableHeaderFilterChange = (value: any, item: any) => {
    // value是选中的子类id
    // item是大类数据
    const {
      id
    } = item || {};

    const fs: any = spuAttrFilter;

    fs[`${id}`] = value;

    setSpuAttrFilter(fs);
    setIndex(index + 1);
  }

  // 规格 已选规格 添加点击
  const handleAddSpec = () => {
    window.open(`/models/${model}?type=0`, '_blank');
  }

  // 规格 已选规格 具体规格 添加点击
  const handleAddSpecList = (item: any) => {
    return () => {
      const {
        id
      } = item;

      window.open(`/models/${model}?type=0&specId=${id}`, '_blank');
    }
  }

  // 属性 已选属性 添加点击
  const handleAddAttr = () => {
    window.open(`/models/${model}?type=1`, '_blank');
  }

  // 属性 已选属性 具体属性 添加点击
  const handleAddAttrList = (item: any) => {
    return () => {
      const {
        id
      } = item;

      window.open(`/models/${model}?type=1&attrId=${id}`, '_blank');
    }
  }

  // 规格 render
  const renderSkuContainer = () => {
    let list: any = [];

    if (validTabKey === '0') {
      list = spuTempNoSku;
    }

    if (validTabKey === '1') {
      const arr = spuHaveInfos.filter((v: any) => {
        const {
          key
        } = v || {};

        if (spuHave && spuHave.indexOf(key) > -1) {
          return true;
        }

        return false;
      });

      list = arr;
    }

    let finalList: any = [];

    // 有筛选
    const fs = Object.keys(spuAttrFilter); // {1: 29}

    // list [{'1_specItemId': "4"}]

    if (fs.length > 0) {
      finalList = list;

      for (let i = 0; i < fs.length; i++) {
        const key = `${fs[i]}`;
        const value = spuAttrFilter[key];

        finalList = finalList.filter((v: any) => {
          const itemValue = v[`${key}_specItemId`];

          if (itemValue === `${value}`) {
            return true;
          }

          return false;
        });
      }
    } else {
      finalList = list;
    }

    const selectLength = spuSelectSpec.length;
    const width = selectLength * 240 + 240 * 3;

    const cellWidth = `${(240 / width) * 100}%`;

    return (
      <div className="asa-tab-content asa-tab-content-one">
        <div className="asa-tc-can-choose">
          <div className="asa-tc-cc-h1">
            {t("skuAttr.optional")}:
          </div>
          <div className="asa-tc-cc-main">
            {
              spuCanSpec.map((v: any) => {
                const {
                  id,
                  nameLang
                } = v || {};

                const finalText = `${id}: ${nameLang}`;

                return (
                  <Tag
                    key={`spuCanSku-${id}`}
                    color={TAG_BG_COLOR}
                    onClick={handleSpuCanSpecClick(v)}
                  >
                    {finalText}
                    <PlusOutlined />
                  </Tag>
                );
              })
            }
          </div>
        </div>
        <div className="asa-tc-already-choose">
          <div className="asa-tc-ac-h1">
            {t("skuAttr.selected")}:
            <Tooltip
              title={t("model.addTextAtt.button")}
            >
              <Button
                type="text"
                icon={<PlusCircleOutlined />}
                onClick={handleAddSpec}
              />
            </Tooltip>
          </div>
          <div className="asa-tc-ac-main">
            {
              spuSelectSpec.map((v: any, i: number) => {
                const {
                  id,
                  item,
                  select = [],
                  nameLang
                } = v;

                const text = `${id}: ${nameLang}`;

                return (
                  <Checkbox.Group
                    className="tab-list"
                    key={`spuSelectSku-${i}`}
                    value={select}
                    onChange={e => handleCheckList(e, i)}
                  >
                    <div className="checkbox-div">
                      <Tooltip
                        title={text}
                        mouseEnterDelay={0.5}
                      >
                        <Tag
                          className="tl-header"
                          onClick={e => handleSpuSelectSpecClick(e, v)}
                        >
                          {text}
                          <CloseOutlined />
                        </Tag>
                      </Tooltip>
                      {
                        item.map((vItem: any, iItem: number) => {
                          const {
                            id: cId,
                            nameLang: cNameLang
                          } = vItem;

                          const text = `${cId}: ${cNameLang}`;

                          return (
                            <div 
                              className={`checkbox-list ${(select && select.indexOf(cId) !== -1) ? 'checkbox-list-checked' : ''}`}
                              key={`spuSelectSku-checbox-${iItem}`}
                            >
                              <Checkbox 
                                className="checkbox"
                                value={cId}
                              >
                                {text}
                              </Checkbox>
                            </div>
                          )
                        })
                      }
                      <Tooltip
                        title={t("model.addTextAtt.button")}
                      >
                        <Button
                          type="text"
                          className="asa-tc-ac-main-add-more-btn"
                          icon={<PlusCircleOutlined />}
                          onClick={handleAddSpecList(v)}
                        />
                      </Tooltip>
                    </div>
                  </Checkbox.Group>
                )
              })
            }
          </div>
        </div>
        <div className="asa-tc-ac-tab">
          <div
            className={`asa-tc-act-valid-common asa-tc-act-valid ${validTabKey === '0' ? 'asa-tc-act-valid-active' : ''}`}
            onClick={handleValidTabClick('0')}
          >
            {t("skuAttr.invalid")}({spuTempNoSku.length})
          </div>
          <div
            className={`asa-tc-act-valid-common asa-tc-act-invalid ${validTabKey === '1' ? 'asa-tc-act-valid-active' : ''}`}
            onClick={handleValidTabClick('1')}
          >
            {t("skuAttr.valid")}({spuHave.length})
          </div>
        </div>
        <div className="aiko-table-div">
          <div className={`aiko-table aiko-table-x ${addWindowsClazz()}`}>
            <div
              className="aikot-header"
              style={{
                minWidth: width
              }}
            >
              {
                spuSelectSpec.map((v: any, i: any) => {
                  const {
                    id,
                    item,
                    select = [],
                    nameLang
                  } = v || {};

                  const finalItem = item.filter((v: any) => {
                    const {
                      id
                    } = v || {};

                    if (select && select.indexOf(id) > -1) {
                      return true;
                    }

                    return false;
                  });

                  const finalSelect = spuAttrFilter[`${id}`] || -1;

                  return (
                    <div
                      className="aikoth-cell"
                      key={`aiko-table-header-${i}`}
                      style={{
                        width: cellWidth
                      }}
                    >
                      {nameLang}
                      <Select
                        size="small"
                        defaultValue={-1}
                        placeholder={t("skuAttr.attrInput")}
                        value={finalSelect}
                        onChange={(value: any) => handleTableHeaderFilterChange(value, v)}
                      >
                        <Option
                          key={`aiko-table-header-option--1-${index}`}
                          value={-1}
                        >
                          {t("skuAttr.selectAll")}
                        </Option>
                        {
                          finalItem.map((iv: any, index: any) => {
                            const {
                              id,
                              nameLang
                            } = iv || {};

                            return (
                              <Option
                                key={`aiko-table-header-option-${id}-${index}`}
                                value={id}
                              >
                                {nameLang}
                              </Option>
                            )
                          })
                        }
                      </Select>
                    </div>
                  )
                })
              }
              <div
                className="aikoth-cell"
                style={{
                  width: cellWidth
                }}
              >
                {t("skuAttr.skuName")}
              </div>
              <div
                className="aikoth-cell"
                style={{
                  width: cellWidth
                }}
              >
                {t("skuAttr.skuCode")}
              </div>
              <div
                className="aikoth-cell aikoth-cell-action"
                style={{
                  width: cellWidth
                }}
              >
                {t("skuAttr.action")}
                <Button
                  type='primary'
                  size='small'
                  onClick={handleResetFilterClick}
                >
                  {t("skuAttr.btnReset")}
                </Button>
                {
                  validTabKey === '0' ? (
                    <Button
                      type='primary'
                      size='small'
                      onClick={handleAllValidClick}
                    >
                      {t("skuAttr.btnAllValid")}
                    </Button>
                  ) : (
                    <Button
                      danger
                      type='primary'
                      size='small'
                      onClick={handleAllInvalidClick}
                    >
                      {t("skuAttr.btnAllInvalid")}
                    </Button>
                  )
                }
              </div>
            </div>
            <div
              className={`aikot-main ${finalList.length === 0 ? 'aikot-empty-main' : ''}`}
              style={{
                minWidth: width
              }}
            >
              {
                finalList.length > 0 ? finalList.map((v: any, i: any) => {
                  const order = v['keys']; // 这是顺序
                  let os = order.split('_');

                  os.push('key_name');
                  os.push('sku');

                  return (
                    <div
                      key={`aiko-table-list-${i}`}
                      className="aikotm-list"
                    >
                      {
                        os.map((k: any, index: any) => {
                          const item = v[k];

                          return (
                            <div
                              className="aikotm-l-cell"
                              key={`aiko-table-list-${i}-${index}`}
                              style={{
                                width: cellWidth
                              }}
                            >
                              <Tooltip
                                title={item}
                                mouseEnterDelay={0.5}
                              >
                                {item}
                              </Tooltip>
                            </div>
                          )
                        })
                      }
                      <div
                        className="aikotm-l-cell aikotm-l-cell-action"
                        style={{
                          width: cellWidth
                        }}
                      >
                        {
                          validTabKey === '0' ? (
                            <Button
                              type='primary'
                              size='small'
                              onClick={handleValidClick(v)}
                            >
                              {t("skuAttr.btnValid")}
                            </Button>
                          ) : (
                            <Button
                              danger
                              type='primary'
                              size='small'
                              onClick={handleInvalidClick(v)}
                            >
                              {t("skuAttr.btnInvalid")}
                            </Button>
                          )
                        }
                      </div>
                    </div>
                  )
                }) : (
                  <Empty description={t("skuAttr.noData")} />
                )
              }
            </div>
          </div>
        </div>
      </div>
    );
  }

  // 属性 render
  const renderAttrContainer = () => {
    return (
      <div className="asa-tab-content asa-tab-content-two">
        <div className="asa-tc-can-choose">
          <div className="asa-tc-cc-h1">
            {t("skuAttr.optional")}:
          </div>
          <div className="asa-tc-cc-main">
            {
              spuCanAttr.map((v: any) => {
                const {
                  id,
                  nameLang
                } = v || {};

                const finalText = `${id}: ${nameLang}`;

                return (
                  <Tag
                    key={`spuCanAttr-${id}`}
                    color={TAG_BG_COLOR}
                    onClick={handleSpuCanAttrClick(v)}
                  >
                    {finalText}
                    <PlusOutlined />
                  </Tag>
                );
              })
            }
          </div>
        </div>
        <div className="asa-tc-already-choose">
          <div className="asa-tc-ac-h1">
            {t("skuAttr.selected")}:
            <Tooltip
              title={t("model.addTextAtt.button")}
            >
              <Button
                type="text"
                icon={<PlusCircleOutlined />}
                onClick={handleAddAttr}
              />
            </Tooltip>
          </div>
          <div className="asa-tc-ac-main">
            {
              spuSelectAttr.map((v: any) => {
                const {
                  id,
                  item,
                  type,
                  select,
                  nameLang
                } = v || {};

                const finalText = `${id}: ${nameLang}`;

                return (
                  <div
                    className="asa-tc-ac-m-list"
                    key={`spuSelectAttr-${id}`}
                  >
                    <Tag
                      onClick={handleSpuSelectAttrClick(v)}
                    >
                      {finalText}
                      <CloseOutlined />
                    </Tag>
                    <Select
                      size="small"
                      placeholder={t("skuAttr.attrInput")}
                      value={select}
                      onChange={(value: any) => handleSkuAttrChange(value, id)}
                    >
                      {
                        item.map((v: any, index: any) => {
                          const {
                            id,
                            nameLang,
                            name_alias
                          } = v || {};

                          let finalAlias = nameLang;

                          if (type === 2) { // 为2的时候 nameLang为html, 需要popover出来
                            finalAlias = name_alias || `${t("skuAttr.attrNameAlias")}${index + 1}`; // 如果没有的话用段落{index}
                          }

                          return (
                            <Option
                              key={`spuSelectAttr-option-${id}`}
                              value={id}
                            >
                              {
                                (type === 2 && nameLang) ? (
                                  <Popover
                                    mouseEnterDelay={0.5}
                                    placement="right"
                                    overlayClassName="asa-spu-select-attr-popover"
                                    content={
                                      <div dangerouslySetInnerHTML={{__html: nameLang}} />
                                    }
                                  >
                                    <div>
                                      {finalAlias}
                                    </div>
                                  </Popover>
                                ) : finalAlias
                              }
                            </Option>
                          );
                        })
                      }
                    </Select>
                    <Tooltip
                      title={t("model.addTextAtt.button")}
                    >
                      <Button
                        type="text"
                        icon={<PlusCircleOutlined />}
                        onClick={handleAddAttrList(v)}
                      />
                    </Tooltip>
                  </div>
                );
              })
            }
          </div>
        </div>
      </div>
    );
  }

  // 非标规格 render
  const renderCusSpecContainer = () => {
    return (
      <div className="asa-tab-content asa-tab-content-three">
        <div className="asa-tc-can-choose">
          <div className="asa-tc-cc-h1">
            {t("skuAttr.optional")}:
          </div>
          <div className="asa-tc-cc-main">
            {
              canCusSpecList.map((v: any) => {
                const {
                  id,
                  nameLang
                } = v || {};

                const finalText = `${id}: ${nameLang}`;

                return (
                  <Tag
                    key={`canCusSpec-${id}`}
                    color={TAG_BG_COLOR}
                    onClick={handleCanCusSpecClick(v)}
                  >
                    {finalText}
                    <PlusOutlined />
                  </Tag>
                );
              })
            }
          </div>
        </div>
        <div className="asa-tc-already-choose">
          <div className="asa-tc-ac-h1">
            {t("skuAttr.selected")}:
          </div>
          <div className="asa-tc-ac-main">
            {
              selectCusSpecList.map((v: any) => {
                const {
                  id,
                  nameLang
                } = v || {};

                const finalText = `${id}: ${nameLang}`;

                return (
                  <Tag
                    key={`selectCusSpec-${id}`}
                    onClick={handleSelectCusSpecClick(v)}
                  >
                    {finalText}
                    <CloseOutlined />
                  </Tag>
                );
              })
            }
          </div>
        </div>
      </div>
    );
  }

  // tab 渲染
  const renderTab = (i: any) => {
    if (i === 0) {
      return renderSkuContainer();
    }

    if (i === 1) {
      return renderAttrContainer();
    }

    if (i === 2) {
      return renderCusSpecContainer();
    }
  }
  
  useEffect(() => {
    getBaseListData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // 设置 规格下面的 sku 的表格
    setSkuTable();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spuHave, spuSelectSpec]);

  return (
    <BaseLayout
      title={t("route.sktAttr")}
      childrenClassName="aiko-sku-attr-layout"
    >
      <Spin
        spinning={loading}
        size='large'
      >
        <div className="aiko-sku-attr bl-padding">
          <div style={{display: 'none'}}>{index}</div>
          <PageHeader
            title={t("route.sktAttr")}
            style={{ padding: "16px 0" }}
          />
          <Card
            className="asa-info-card"
          >
            <div className="asa-ic-main">
              <div className="asa-icm-left">
                <div className="asa-icm-list asa-icm-spu">
                  <div className="asa-icm-l-title">
                    SPU:
                  </div>
                  <div className="asa-icm-l-label asa-icm-l-label-text">
                    {info.spu}
                  </div>
                </div>
                <div className="asa-icm-list asa-icm-model">
                  <div className="asa-icm-l-title">
                    {t("skuAttr.model")}:
                  </div>
                  <div className="asa-icm-l-label">
                    <Select
                      placeholder={t("skuAttr.modelInput")}
                      value={model}
                      onChange={handleModelChange}
                      options={modelList}
                    />
                  </div>
                </div>
                <div className="asa-icm-list asa-icm-same">
                  <div className="asa-icm-l-title">
                    {t("skuAttr.same")}:
                  </div>
                  <div className="asa-icm-l-label">
                    {
                      !sameSpuEdit ? (
                        <div className="asa-icm-l-label asa-icm-l-label-text">
                          {
                            (sameSpu && sameSpu.nameLang)
                              ? sameSpu.nameLang
                              : t('')
                          }
                        </div>
                      ) : (
                        <Select
                          showSearch
                          loading={sameSpuLoading}
                          placeholder={t("skuAttr.sameInput")}
                          onChange={handleSpuSameChange}
                          onSearch={handleSpuSameSearch}
                          filterOption={false}
                          notFoundContent={null}
                          options={sameSpuList.map((v: any) => {
                            return {
                              value: v.id,
                              label: v.name_lang
                            }
                          })}
                        />
                      )
                    }
                    <EditOutlined
                      onClick={handleSpuSameEditClick}
                    />
                  </div>
                </div>
              </div>
              <div className="asa-icm-right">
                <Button
                  type='primary'
                  disabled={skuSubmitLoading || attrSubmitLoading || cusSpecSubmitLoading}
                  onClick={handleRefreshClick}
                >
                  {t("skuAttr.refresh")}
                </Button>
              </div>
            </div>
          </Card>
          <Card
            className="asa-tab-card"
            style={{ marginTop: 24 }}
            bodyStyle={{ paddingTop: 4, paddingLeft: 2, paddingRight: 2, paddingBottom: 4 }}
          >
            <div className="asa-tab">
              <Tabs
                activeKey={tabKey}
                onChange={handleTabChange}
                type="card"
                items={new Array(3).fill(null).map((_, i) => {
                  const label = labelName[i];

                  return {
                    label: label,
                    key: `${i}`,
                    children: renderTab(i),
                  };
                })}
              />
              <div className="asa-tc-button">
              {
                  tabKey === '0' ? (
                    <Button
                      type='primary'
                      loading={skuSubmitLoading}
                      onClick={handleSkuSubmitClick}
                    >
                      {t("skuAttr.submit")}
                    </Button>
                  ) : null
                }
                {
                  tabKey === '1' ? (
                    <Button
                      type='primary'
                      loading={attrSubmitLoading}
                      onClick={handleAttrSubmitClick}
                    >
                      {t("skuAttr.submit")}
                    </Button>
                  ) : null
                }
                {
                  tabKey === '2' ? (
                    <Button
                      type='primary'
                      loading={cusSpecSubmitLoading}
                      onClick={handleCusSpecSubmitClick}
                    >
                      {t("skuAttr.submit")}
                    </Button>
                  ) : null
                }
              </div>
            </div>
          </Card>
        </div>
      </Spin>
    </BaseLayout>
  )
}

export default SkuAttr;