/* eslint-disable react-hooks/exhaustive-deps */
import {
  SpuPointsDataset,
  SeriesParallelForm,
  PerformanceFormField,
} from "../../../stores/spuPoints";

import Chart, { EChartsOption } from "../../../components/Chart";

import { useEffect, useState } from "react";

import { rangeRight, genRegressionModal, zip } from "../../../utils";

import {
  genRenderItem,
  exportTemplate,
  renderTypeLabel,
} from "../../../libs/echar";

import { useTranslation } from "react-i18next";

import useSpuPdfParam from "../../../stores/spuPdfParam";

import { EChartsType } from "echarts/core";

// 串并联曲线图

interface SeriesParallelChartProps {
  name: string;
  dataset: SpuPointsDataset;
  performance: PerformanceFormField;
  seriesParallel: SeriesParallelForm;
}

const SeriesParallelChart = ({
  name,
  dataset,
  performance,
  seriesParallel,
}: SeriesParallelChartProps) => {
  const { t } = useTranslation();

  const initialOption: EChartsOption = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross"
      }
    },
    toolbox: {
      feature: {
        saveAsImage: {
          name: name + "_sp"
        }
      }
    },
    xAxis: {
      name: "m³/h",
      type: "value",
      min: 0
    },
    yAxis: {
      name: "H(m)",
      type: "value",
      min: 0
    }
  };

  const [option, setOption] = useState(initialOption);

  useEffect(() => {
    const {
      type,
      quantity
    } = seriesParallel;

    const QH = genRegressionModal(zip(dataset.Q, dataset.H));

    const range = rangeRight(quantity, 1);

    const newSeries: any = range.map((k: any) => {
      const data = QH.dummyPoints.map((p: any) => {
        if (type === "series") { // 串联
          return [p[0], p[1] * k];
        }
        
        return [p[0] * k, p[1]]; // 并联
      });

      return {
        name: `Line ${k}`,
        type: "line",
        symbolSize: 0,
        // lineStyle: {
        //   width: 1,
        //   color: '#000'
        // },
        data,
        animation: false
      };
    });

    const {
      quantity: q,
      head: h
    } = performance;

    let series = [
      ...newSeries,
      {
        name: "text 1",
        type: "custom",
        renderItem: renderTypeLabel(
          400,
          50,
          type === "series"
            ? t("seriesParallelChart.type.series")
            : t("seriesParallelChart.type.parallel")
        ),
        data: [{ x: 0, y: 0 }],
      },
    ];

    if (q !== undefined && h !== undefined && quantity > 1) {
      series = [
        ...series,
        {
          name: "intersection point",
          type: "custom",
          renderItem: genRenderItem("circle", q, h),
          data: [
            {
              name: "intersection point",
              xAxis: q,
              yAxis: h,
            },
          ],
          z: 100,
        },
      ];
    }

    setOption({
      ...initialOption,
      series,
    });
  }, [
    JSON.stringify(seriesParallel),
    JSON.stringify(performance),
    JSON.stringify(dataset)
  ]);

  const {
    setSeriesParallel
  } = useSpuPdfParam();

  const handleChartChange = (instance: EChartsType) => {
    const result = instance.getDataURL(exportTemplate as any);

    const {
      quantity
    } = seriesParallel;

    instance.getDataURL();

    if (quantity > 1) {
      setSeriesParallel(result);
    }
  };

  return (
    <Chart
      option={option}
      notMerge
      style={{ width: 500, height: 600, margin: "auto" }}
      onChange={handleChartChange}
    />
  );
};

export default SeriesParallelChart;
