import regreisson, { DataPoint, Result } from "regression";
import { SpuPointsDataset } from "./stores/spuPoints";
import { DataSource, Dataset, CurveModel, ProccessedSpuData } from "./types";

// 求解二次型
const solveQuadratic = (a: number, b: number, c: number): number | void => {
  const delta = b ** 2 - 4 * a * c; // b² - 4ac

  if (delta < 0) {
    return;
  }

  const sqrtDelta = Math.sqrt(delta); // √￣b² - 4ac

  const add = -b + sqrtDelta;
  const subtract = -b - sqrtDelta;

  const denominator = 2 * a; // 分母

  if (delta === 0) {
    return add / denominator;
  }

  const x1 = add / denominator;
  const x2 =  subtract / denominator;
  const result = Math.max(x1, x2);

  if (result < 0) {
    return;
  }

  return result;
};

// 求解四分位数 [根据翻译是这样]
const shortestSolveQuartic = (
  a: number,
  b: number,
  c: number,
  d: number,
  e: number,
  center: number = 0
): number | void => {
  const df = (x: number) => a * x ** 4 + b * x ** 3 + c * x ** 2 + d * x + e;
  const dx = (x: number) => 4 * a * x ** 3 + 3 * b * x ** 2 + 2 * c * x + d;

  for (let x = center, xn = 0, i = 0; i < 100; x = xn, i += 1) {
    xn = x - df(x) / dx(x);

    if (Math.abs(x - xn) < 1e-9) {
      return xn;
    }
  }

  return;
};

// 查找交点 [获取辅助线与QH1的交点]
export const findIntersectionPoint = (
  q: number,
  h: number,
  equation: Array<number>,
  type: number = 0,
  center: number = 0
): Array<number> | void => {
  const [a, b, c] = equation;
  let k, q1, h1;

  if (type !== 0) { // 公式2
    k = Math.pow(h, 2) / Math.pow(q, 3);

    q1 = shortestSolveQuartic(
      a ** 2,
      2 * a * b - k,
      b * b + 2 * a * c,
      2 * b * c,
      c ** 2,
      center
    );
  } else { // 公式1
    k = h / q ** 2;

    q1 = solveQuadratic(a - k, b, c);
  }

  if (!q1) {
    return;
  }

  h1 = q1 ** 2 * a + q1 * b + c;

  return [q1, h1];
};

// 回归模型
export const genRegressionModal = (
  dataset: DataPoint[],
  cbrt: any = false,
  isSelected: any = false
): CurveModel => {
  const result = regreisson.polynomial(dataset, {
    order: 2,
    precision: 10 // 精度
  });

  const {
    predict,
    points
  } = result;

  const firstPoint = points[0];
  const lastPoint = points[points.length - 1];

  // 根据第一个点，和最后一个点，中间找到100个点，来绘制canvas line
  return {
    ...result,
    dummyPoints: generateData(
      (x) => predict(x)[1],
      firstPoint[0],
      lastPoint[0],
      (lastPoint[0] - firstPoint[0]) / 100,
      cbrt,
      isSelected
    ),
  };
};

export const precision = (n: number, p: number): number => {
  const finalN = Number(n)

  // 当精度为 0 的时候，四舍五入取整
  if (p === 0) return Math.round(finalN);

  // 否则按照精度，在小数位四舍五入取整
  return Number(finalN.toFixed(p));
};

const ceil10 = (n: number): number => {
  if (n % 10 === 0) {
    return n;
  }

  return n - (n % 10) + 10;
};

// 计算 Y 轴
export const calcYAxis = (
  Y: Array<number>, // 数据
  splitNumber: number, // 比例尺
  padding?: number // 间隔
): [max: number, interval: number] => {
  let max = Math.max(...Y);

  if (padding) {
    max /= (splitNumber - padding) / splitNumber;
  }

  max = max < 10 ? Math.ceil(max) : ceil10(max);
  const interval = max / splitNumber;

  return [max, interval];
};

// 变成数组
export const zip = (a: Array<any>, b: Array<any>): Array<any> =>
  a.map((e, i) => [e, b[i]]);

export const mapand = (
  lst: Array<any>,
  fn: (item: any) => boolean
): boolean => {
  if (lst.length === 0) return true;
  if (!fn(lst[0])) return false;

  return mapand(lst.slice(1), fn);
};

export const mapor = (lst: Array<any>, fn: (item: any) => boolean): boolean => {
  if (lst.length === 0) return false;
  if (fn(lst[0])) return true;

  return mapor(lst.slice(1), fn);
};

// 功率公式
export const powerFormula = (point: DataPoint, eff: number): number => {
  const [q, h] = point;

  if (eff === 0) {
    return 0;
  }

  if (q <= 0 || h <= 0) {
    return 0;
  }

  return (q * h) / 367.2 / eff;
};

export const createQP = (
  QH: ReadonlyArray<DataPoint>,
  QEFF: ReadonlyArray<DataPoint>,
  sg: number = 1 // 比重
): Array<DataPoint> => {
  return QH.map((point, i) => {
    if (i === 0) { // QH的第一个点(特殊计算) 取QH和QEFF的第二个点 * 0.9 * 比重
      return [point[0], powerFormula(QH[1], QEFF[1][1] / 100) * 0.9 * sg];
    }

    return [point[0], powerFormula(point, QEFF[i][1] / 100) * sg];
  });
};

// 分析数据集
export const parseDataset = (dataset: Dataset): DataSource => {
  const QH = genRegressionModal(zip(dataset.Q, dataset.H));
  const QEFF = genRegressionModal(zip(dataset.Q, dataset.EFF));
  const QP = createQP(QH.points, QEFF.points);

  let result: DataSource = {
    name: dataset.name,
    type: dataset.type,
    frequency: dataset.frequency,
    diameter: dataset.diameter,
    speed: dataset.speed,
    range: dataset.range,
    QH,
    QP,
    QEFF,
  };

  if (dataset.NPSH)
    result.QNPSH = genRegressionModal(zip(dataset.Q, dataset.NPSH));
  if (dataset.diameter) result.diameter = dataset.diameter;

  return result;
};

// 生成数据
export const generateData = (
  equation: (x: number) => any,
  start: number,
  end: number,
  step: number,
  cbrt: boolean = false,
  isSelected: boolean = true
): DataPoint[] => {
  const data: DataPoint[] = [];

  for (let x = start; x < end; x += step) {
    if (cbrt) {
      let finalY = equation(x);

      if (x > 0) {
        const finalX = Math.sqrt(Math.sqrt(x));
        
        if (finalY > 0) {
          if (isSelected) {
            finalY = Math.cbrt(finalY);
          } else {
            finalY = Math.sqrt(Math.cbrt(finalY));
          }
  
          data.push([finalX, finalY]);
        }
      }
  
      if (x + step >= end) {
        let finalEndY = equation(end);
        const finalEndX = Math.sqrt(Math.sqrt(end));

        if (finalEndY > 0) {
          if (isSelected) {
            finalEndY = Math.cbrt(finalEndY);
          } else {
            finalEndY = Math.sqrt(Math.cbrt(equation(end)));
          }

          data.push([finalEndX, finalEndY]);
        }
      }
    } else {
      data.push([x, equation(x)]);
  
      if (x + step >= end) {
        data.push([end, equation(end)]);
      }
    }
  }

  return data;
};

// echart custom 文本
export const formatTextLine = (
  label: string,
  n: number,
  unit: string | undefined
) => {
  if (unit) {
    return `${label}: ${n} ${unit}`;
  }

  return `${label}: ${n}`;
};

// 10, 1 -> [10, 9, 8, 7, 6, 5, 4, 3, 2, 1]
export const rangeRight = (n: number, startAt: number = 0) => {
  const result = [];

  while (n > 0) {
    result.push(--n + startAt);
  }

  return result;
};

export const getSizeUnit = (size: number | undefined | null): "mm" | "%" => {
  return size ? "mm" : "%";
};

// 根据交点 生成 K
export const createK = (q: number, end: number, type: number = 0): number => {
  if (type !== 0) {
    return Math.sqrt(q / end);
  }

  return q / end;
};

export const stockMap: any = {
  all: undefined,
  outOfStock: 0,
  inStock: 1,
};

export const isSpuPointNotEmpty = (spu: ProccessedSpuData): boolean => {
  const ql = spu.Q.length;
  const hl = spu.H.length;
  const effl = spu.EFF.length;
  const npshl = spu.NPSH.length;

  if (npshl > 0) return Boolean(ql && hl && effl && npshl);
  return Boolean(ql && hl && effl);
};

export const parseSelected = (strList: string[] | null) => {
  if (strList === null || (strList.length === 1 && strList[0] === '')) {
    return []
  };

  return strList.map((str) => parseInt(str, 10));
};

// 创建辅助线数据
export const createAuxiliaryData = (
  q: number,
  h: number,
  start: number,
  end: number,
  type: number
) => {
  let result, formula;

  if (type !== 0) { // 公式2
    formula = (x: number) =>
      Math.sqrt((Math.pow(h, 2) / Math.pow(q, 3)) * x ** 3);
  } else { // 公式1
    formula = (x: number) => (h / q ** 2) * x ** 2;
  }

  result = generateData(formula, start, end, 0.1);

  return result;
};

// 根据 K, 之前的 QH, 公式 生成 QH2
export const createQH2Data = (
  k: number,
  oldQHData: DataPoint[],
  type: number
): DataPoint[] => {
  let newQHData: DataPoint[];

  if (type !== 0) {
    newQHData = oldQHData.map((p) => {
      return [p[0] * k ** 2, p[1] * k ** 3];
    });
  } else {
    newQHData = oldQHData.map((p) => {
      return [p[0] * k, p[1] * k ** 2];
    });
  }

  return newQHData;
};

// 根据 K, 之前的 QEFF, 公式 生成 QEFF2
export const createQEFF2Data = (
  k: number,
  oldQEFFData: DataPoint[],
  type: number = 0
) => {
  let newQEFFData: DataPoint[];
  const cbrtK = Math.cbrt(k);

  if (type !== 0) {
    newQEFFData = oldQEFFData.map((p) => [p[0] * k ** 2, p[1] * cbrtK]);
  } else {
    newQEFFData = oldQEFFData.map((p) => [p[0] * k, p[1] * cbrtK]);
  }

  return newQEFFData;
};

export const createQNPSH2Data = (
  k: number,
  oldQNPSHData: DataPoint[],
  type: number
) => {
  if (!oldQNPSHData || oldQNPSHData.length === 0) {
    return [];
  }

  let newQNPSHData: DataPoint[];

  if (type !== 0) {
    newQNPSHData = oldQNPSHData.map((p) => [p[0] * k ** 2, p[1] * k ** 2]);
  } else {
    newQNPSHData = oldQNPSHData.map((p) => [p[0] * k, p[1] * k ** 2]);
  }

  return newQNPSHData;
};

// 创建新的叶轮直径 [计算叶轮尺寸]
export const createDiameterData = (k: number, size: number, type: number) => {
  let diameter = 0;
  let diameterUnit = "mm";

  if (size) {
    if (type !== 0) {
      diameter = size * k;
    } else {
      diameter = size;
    }
  } else {
    diameterUnit = "%";

    if (type !== 0) {
      diameter = k * 100;
    } else {
      diameter = 100;
    }
  }

  return {
    diameter,
    diameterUnit
  };
};

// 创建新的转速 [计算转速]
export const createSpeedData = (k: number, speed: number, type: number) => {
  if (type !== 0) {
    return speed;
  }
  return speed * k;
};

// 计算频率
export const createFrequencyData = (
  k: number,
  frequency: number,
  type: number
) => {
  if (type === 0) {
    return frequency * k;
  }

  return frequency;
};

export const convertEFF1ToEFF2 = (
  oldQEFFObject: Result,
  oldQ: number, // 交点
  k: number
) => {
  return oldQEFFObject.predict(oldQ)[1] * Math.cbrt(k);
}

interface SpuPointsDataSourceBase {
  QH: CurveModel;
}

interface SpuPointsDataSource extends SpuPointsDataSourceBase {
  QP: DataPoint[];
  QEFF: CurveModel;
  QNPSH?: CurveModel;
}

// 解析spu 点 数据
export const parseSpuPointsDataset = (
  dataset: SpuPointsDataset
): SpuPointsDataSource => {
  const QH = genRegressionModal(zip(dataset.Q, dataset.H));
  const QEFF = genRegressionModal(zip(dataset.Q, dataset.EFF));
  const QP = createQP(QH.points, QEFF.points);
  const QNPSH = dataset.NPSH && genRegressionModal(zip(dataset.Q, dataset.NPSH));
  const result = { QH, QP, QEFF, QNPSH };

  return result;
};

// 过滤相近数据
export const filterCloseArray = (arr: any) => {
  let finalArr: any = arr;

  const length = arr.length;

  const times = Math.ceil(length / 1000) * 2; // 循环次数

  for (let j = 0; j < Number(times); j++) {
    const forArr: any = finalArr;
    finalArr = [];

    for (let i in forArr) {
      const index = Number(i);

      if ((index % 2) === 1) { // 奇数 continue
        continue;
      }
  
      const now = forArr[index];
      const next = forArr[index + 1];

      const {
        Q = [],
        H = []
      } = now || {};
  
      const {
        Q: NQ = [],
        H: NH = []
      } = next || {};
  
      let close = true;
  
      if ((Q.length > 0 && NQ.length > 0) && Math.abs(Q.length - NQ.length) < 5) { // q 长度相近
        for (let ii in Q) {
          const qv = Q[ii] || 0;
          const nqv = NQ[ii] || 0;
  
          const dValue = Math.abs(qv - nqv);
  
          if (dValue > 200) {
            close = false;
            break;
          }
        }
  
        if (close) { // 上面校验通过了
          if (H.length > 0 && NH.length > 0) {
            for (let ii in H) {
              const hv = H[ii] || 0;
              const nhv = NH[ii] || 0;
      
              const dValue = Math.abs(hv - nhv);
      
              if (dValue > 400) {
                close = false;
                break;
              }
            }
          }
        }
      } else {
        close = false;
      }
  
      if (now) {
        finalArr.push(now);
      }
  
      if (!close) {
        if (next) {
          finalArr.push(next);
        }
      }
    }
  }

  return finalArr;
}

// 二次过滤相近数据
export const filterCloseTwoArray = (arr: any) => {
  let finalArr: any = arr;

  // 根据qtotal排序，这里只能过滤中间的数据
  finalArr = finalArr.sort((a: any, b: any) => {
    const {
      QTotal: aq
    } = a;

    const {
      QTotal: bq
    } = b;

    return aq - bq;
  });

  for (let j = 0; j < 5; j++) {
    const forArr: any = finalArr;
    finalArr = [];

    const length = forArr.length;
    const half: any = length / 2;
    const nHalf = parseInt(half);

    const hhalf: any = nHalf / 1.3;
    const nHhalf = parseInt(hhalf);

    const end = nHalf + nHhalf;
    
    for (let i = 0; i <= nHhalf; i++) {
      const now = forArr[i];

      finalArr.push(now);
    }

    for (let i = nHhalf; i < end; i++) {
      if ((i % 2) === 1) { // 奇数 continue
        continue;
      }

      const now = forArr[i];
      const next = forArr[i + 1];

      const {
        Q = [],
        H = []
      } = now || {};
  
      const {
        Q: NQ = [],
        H: NH = []
      } = next || {};
  
      let close = true;

      if ((Q.length > 0 && NQ.length > 0) && Math.abs(Q.length - NQ.length) < 5) { // q 长度相近
        for (let ii in Q) {
          const qv = Q[ii] || 0;
          const nqv = NQ[ii] || 0;
  
          const dValue = Math.abs(qv - nqv);
  
          if (dValue > 200) {
            close = false;
            break;
          }
        }
  
        if (close) { // 上面校验通过了
          if (H.length > 0 && NH.length > 0) {
            for (let ii in H) {
              const hv = H[ii] || 0;
              const nhv = NH[ii] || 0;
      
              const dValue = Math.abs(hv - nhv);
      
              if (dValue > 400) {
                close = false;
                break;
              }
            }
          }
        }
      } else {
        close = false;
      }
  
      if (now) {
        finalArr.push(now);
      }
  
      if (!close) {
        if (next) {
          finalArr.push(next);
        }
      }
    }

    for (let i = end; i < length; i++) {
      const now = forArr[i];

      finalArr.push(now);
    }
  }

  // 根据htotal排序，这里只能过滤中间的数据
  finalArr = finalArr.sort((a: any, b: any) => {
    const {
      HTotal: ah
    } = a;

    const {
      HTotal: bh
    } = b;

    return ah - bh;
  });

  for (let j = 0; j < 5; j++) {
    const forArr: any = finalArr;
    finalArr = [];

    const length = forArr.length;
    const half: any = length / 2;
    const nHalf = parseInt(half);

    const hhalf: any = nHalf / 1.3;
    const nHhalf = parseInt(hhalf);

    const end = nHalf + nHhalf;
    
    for (let i = 0; i <= nHhalf; i++) {
      const now = forArr[i];

      finalArr.push(now);
    }

    for (let i = nHhalf; i < end; i++) {
      if ((i % 2) === 1) { // 奇数 continue
        continue;
      }

      const now = forArr[i];
      const next = forArr[i + 1];

      const {
        Q = [],
        H = []
      } = now || {};
  
      const {
        Q: NQ = [],
        H: NH = []
      } = next || {};
  
      let close = true;

      if ((H.length > 0 && NH.length > 0) && Math.abs(H.length - NH.length) < 5) { // h 长度相近
        for (let ii in H) {
          const qv = Q[ii] || 0;
          const nqv = NQ[ii] || 0;
  
          const dValue = Math.abs(qv - nqv);
  
          if (dValue > 200) {
            close = false;
            break;
          }
        }
  
        if (close) { // 上面校验通过了
          if (H.length > 0 && NH.length > 0) {
            for (let ii in H) {
              const hv = H[ii] || 0;
              const nhv = NH[ii] || 0;
      
              const dValue = Math.abs(hv - nhv);
      
              if (dValue > 400) {
                close = false;
                break;
              }
            }
          }
        }
      } else {
        close = false;
      }
  
      if (now) {
        finalArr.push(now);
      }
  
      if (!close) {
        if (next) {
          finalArr.push(next);
        }
      }
    }

    for (let i = end; i < length; i++) {
      const now = forArr[i];

      finalArr.push(now);
    }
  }

  return finalArr;
}

export function isWindows() {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return /windows nt/.test(userAgent);
}

export function addWindowsClazz() {
  if (isWindows()) {
    return 'aiko-is-windows';
  }

  return '';
}


export function getUrlParam(url: any) {
  const requestParameters: any = {};

  if (!url) {
    return requestParameters;
  }

  const urlArr = url.split('?');

  if (urlArr[1]) {
    const urlParameters = urlArr[1].split('#')[0];

    if (urlParameters.indexOf('?') === -1) {
      const parameters = decodeURI(urlParameters);
      const parameterArray = parameters.split('&');

      for (let i = 0; i < parameterArray.length; i++) {
        requestParameters[parameterArray[i].split('=')[0]] = (parameterArray[i].split('=')[1]);
      }
    }
  }

  return requestParameters;
}