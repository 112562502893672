import axios from "axios";

import { message, notification } from "antd";

// 正式环境
const BASE_URL = (() => {
  const hostname = window.location.hostname

  const dev = ['0.0.0.0', 'localhost']
  const test = ['select-test.xiaozhouiot.com']

  if (dev.indexOf(hostname) > -1) {
    return 'https://aikon-api-service-test.aikoncloud.com/api/v1'
  }

  if (test.indexOf(hostname) > -1) {
    return 'https://aikon-api-service-test.aikoncloud.com/api/v1'
  }
  
  return 'https://point-service.xiaozhouiot.com/api/v1'
})()

const instance = axios.create();

let adminForWard: boolean = false;

instance.interceptors.request.use(
  (config) => {
    const auth = window.localStorage.getItem("aikonLoggedUser");
    const token = auth ? JSON.parse(auth).token : undefined;
    config.headers.Authorization = `Bearer ${token}`;

    const lang = window.localStorage.getItem("i18nextLng");
    if (lang) config.headers["Accept-Language"] = lang;

    config.withCredentials = false
    config.baseURL = BASE_URL;

    return config;
  },
  (error) => {
    notification["error"](error.toJSON());
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res) => {
    const {
      error
    } = res.data;

    if (error) {
      notification["error"](error);

      if (error.code === 'bearerTokenError') {
        const auth = window.localStorage.getItem("aikonLoggedUser");
        const role = auth ? JSON.parse(auth).role : undefined;

        window.localStorage.removeItem("aikonLoggedUser");

        if (role === "ROLE_ADMIN") {
          adminForWard = true;

          setTimeout(() => {
            adminForWard = false;
          }, 300)

          window.location.replace("/admin");
        } else {
          if (adminForWard) {
            return;
          }

          window.location.replace("/login");
        }
      }

      throw new Error(error);
    }

    return res;
  },
  (error) => {
    notification["error"](error.toJSON());

    // 当出现 401 状态时，清除本地登录信息，并且跳转到对应的登录页面
    if (error.response && error.response.status === 401) {
      const auth = window.localStorage.getItem("aikonLoggedUser");
      const role = auth ? JSON.parse(auth).role : undefined;

      window.localStorage.removeItem("aikonLoggedUser");

      if (role === "ROLE_ADMIN") {
        adminForWard = true;

        setTimeout(() => {
          adminForWard = false;
        }, 300)

        window.location.replace("/admin");
      } else {
        if (adminForWard) {
          return;
        }

        window.location.replace("/login");
      }
    }

    // 当出现 500 状态，并且返回值包括 have_son 字段时，提示用户是否删除子目录
    if (
      error.response &&
      error.response.status === 500 &&
      error.response.data?.error?.message === "have_son"
    ) {
      message.error("该文件夹下有子文件夹，请先删除子文件夹");
    }

    return Promise.reject(error);
  }
);

export { BASE_URL };
export default instance;
