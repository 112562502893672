import { DownOutlined, UpOutlined } from "@ant-design/icons";

import { useSearchParams } from "react-router-dom";

import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import {
  Button,
  Card,
  Form,
  InputNumber,
  PageHeader,
  Select,
  Space,
  Input,
  Spin
} from "antd";

import BaseLayout from "../../components/BaseLayout";
import SelectionTable from "./components/SelectionTable";
import SelectionChart from "./components/SelectionChart";
import SelectionResult from "./components/SelectionResult";
import CategorySelector from "../../components/CategorySelector";

import { convertEFF1ToEFF2, createK, createQP, findIntersectionPoint, genRegressionModal, isSpuPointNotEmpty, parseSelected, powerFormula, precision, stockMap, zip } from "../../utils";

import { ProccessedSpuData, SpuFullData, Stock, Range, } from "../../types";

import spuServices from "../../services/spu";

import './index.css';

const { Option } = Select;
const { useForm } = Form;

let oldQuantity: any = '';
let oldHead: any = '';

// 选型

const createWorkPoint = (q: number, h: number, spu: ProccessedSpuData) => {
  // 1. find intersection point
  if (!spu.QH || !spu.QEFF) return spu;
  const intersectionPoint = findIntersectionPoint(q, h, spu.QH?.equation);
  // 2. create the value of k
  if (!intersectionPoint) return spu;
  const k = createK(q, intersectionPoint[0]);
  // 3. use k to calculate eff/p/npsh?
  const eff = convertEFF1ToEFF2(spu.QEFF, intersectionPoint[0], k);
  const p = powerFormula([q, h], eff / 100);
  // 4. return new object
  if (spu.QNPSH) {
    const npsh = spu.QNPSH.predict(q)[1];

    return {
      ...spu,
      q,
      h,
      eff: precision(eff, 2),
      p: precision(p, 2),
      npsh: precision(npsh, 2),
    };
  }

  return {
    ...spu,
    q,
    h,
    eff: precision(eff, 2),
    p: precision(p, 2),
  };
};

const isPolyIntersected = (
  q: number,
  h: number,
  toleranceStart: number,
  toleranceEnd: number,
  spuEquation: number[],
  start: number,
  end: number
) => {
  const auxEquation = [h / q ** 2, 0, 0];
  const [a, b, c] = auxEquation.map((e, i) => e - spuEquation[i]);
  const delta = b ** 2 - 4 * a * c;
  if (delta < 0) return false;

  const x1 = (-b + Math.sqrt(delta)) / (2 * a);
  const x2 = (-b - Math.sqrt(delta)) / (2 * a);
  const x = Math.max(x1, x2);
  const r1 = { min: start, max: end };
  const r2 = { min: q * toleranceStart, max: q * toleranceEnd };

  return x >= r1.min && x <= r1.max && x >= r2.min && x <= r2.max;
};

const isRangeOverlap = (range: Range, aux: Range) => {
  const mostLeft = Math.min(range.min, range.max, aux.min, aux.max);
  const mostRight = Math.max(range.min, range.max, aux.min, aux.max);
  const rangeLength = range.max - range.min;
  const auxLength = aux.max - aux.min;

  return mostRight - mostLeft <= rangeLength + auxLength;
};

const selectionProccess = (
  q: number,
  h: number,
  toleranceStart: number,
  toleranceEnd: number,
  results: SpuFullData[]
) => {
  if (!results) return [];
  if (results.length === 0) return results;

  let newResults: ProccessedSpuData[] = results;
  // 0. 过滤掉没有数据点的Spu
  newResults = newResults.filter(isSpuPointNotEmpty);

  // 1. 根据区间长度过滤一部分数据
  newResults = newResults.filter((spu: any) =>
    isRangeOverlap(
      { min: spu.start, max: spu.end },
      { min: q * (toleranceStart / 100), max: q * (toleranceEnd / 100) }
    )
  );
  // console.log("after range overlap", newResults);
  // 2. 获取过滤后的完整数据
  // 3. 根据矩形区域过滤一部分数据
  // newResults = newResults.filter((spu) =>
  //   isRectangleOverlap(q, h, {
  //     p: findStartPoint(spu.Q as number[], spu.H as number[], spu.start),
  //     q: findEndPoint(spu.Q as number[], spu.H as number[], spu.end),
  //   })
  // );
  // console.log("after rectangle overlap", newResults);
  // 4. 对剩余的数据进行线性回归
  // 5. 使用交点公式进行最后的过滤
  newResults = newResults
    .map((spu) => {
      const QH = genRegressionModal(zip(spu.Q as any, spu.H as any));
      const QEFF = genRegressionModal(zip(spu.Q as any, spu.EFF as any));
      const QP = createQP(QH.points, QEFF.points);

      if (spu.NPSH?.length) {
        return {
          ...spu,
          QH,
          QEFF,
          QP,
          QNPSH: genRegressionModal(zip(spu.Q as any, spu.NPSH as any)),
        };
      }

      return {
        ...spu,
        QH,
        QEFF,
        QP,
      };
    })
    .filter((spu) =>
      isPolyIntersected(
        q,
        h,
        toleranceStart / 100,
        toleranceEnd / 100,
        spu.QH.equation,
        spu.start,
        spu.end
      )
    )
    .map((spu) => createWorkPoint(q, h, spu));

  newResults = newResults.filter((spu: any) => {
    const {
      p,
      pLimit
    } = spu;

    let flag: boolean = false;

    if (pLimit && pLimit > 0) {
      if (pLimit >= p * 1.1 && pLimit <= p * 2) {
        flag = true;
      }
    }

    if (pLimit === '0' || pLimit === 0) {
      flag = true;
    }

    return flag;
  });

  newResults = newResults.sort((a: any, b: any) => b.eff - a.eff);

  return newResults;
};

const parseFullSpus = (spus: any): SpuFullData[] => {
  return spus.map((item: any) => ({
    id: item.id,
    spu: item.spu,
    start: item.valid_start || item.start,
    end: item.valid_end || item.end,
    Q: item.q_coordinates,
    H: item.h_coordinates,
    EFF: item.eff_coordinates,
    NPSH: item.npsh_coordinates,
    pLimit: item.p_limit,
    name: item.spu_name,
    speed: item.speed,
    freq: item.freq
  }));
};

const parseStock = (str: string | null): Stock => {
  if (str === null) return "all";
  if (["all", "inStock", "outOfStock"].includes(str)) return str as Stock;
  return "all";
};

const SpuSelection = () => {
  const [expanded, setExpanded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filteredSpus, setFilteredSpus] = useState([]);

  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchRef] = useForm();
  const [selectorRef] = useForm();

  const q = Number(searchParams.get("q")) || undefined;
  const h = Number(searchParams.get("h")) || undefined;

  const toleranceStart = Number(searchParams.get("ts")) || 95; // 容差
  const toleranceEnd = Number(searchParams.get("te")) || 120; //  容差

  const stock: Stock = parseStock(searchParams.get("stock"));
  const selected: number[] = parseSelected(searchParams.getAll("c"));

  const handleReset = () => {
    searchRef.resetFields();
    selectorRef.resetFields();
    setSearchParams({});
  };

  const getSpuSelection = async () => {
    if (q && h) {
      setLoading(true);

      const res = await spuServices.getSelection({
        page: 1,
        limit: 2000,
        full: 0,
        q,
        stock: stockMap[stock],
        cat: selected.join(",") || undefined,
      });
  
      const { spus } = res.data.response;

      const processedSpus = parseFullSpus(spus);

      const filteredSpus: any = selectionProccess(
        q,
        h,
        toleranceStart,
        toleranceEnd,
        processedSpus
      );

      setFilteredSpus(filteredSpus);
      setLoading(false);
    }
  }

  const handleFormChange = () => {
    searchRef.setFieldsValue({ q, h, toleranceStart, toleranceEnd, stock });
    selectorRef.setFieldsValue({ category: { selected } });
    
    getSpuSelection();
  };

  const handleSubmit = (value: any, type: any = 'normal') => {
    let q: any = undefined;
    let h: any = undefined;
    let stock: any = undefined;
    let toleranceStart: any = undefined;
    let toleranceEnd: any = undefined
    
    if (type === 'chart') {
      const {
        q: sq,
        h: sh,
        stock: sStock,
        toleranceStart: sToleranceStart,
        toleranceEnd: sToleranceEnd
      } = searchRef.getFieldsValue();

      q = sq;
      h = sh;
      stock = sStock;
      toleranceStart = sToleranceStart;
      toleranceEnd = sToleranceEnd;
    } else {
      const {
        q: vq,
        h: vh,
        stock: vStock,
        toleranceStart: vToleranceStart,
        toleranceEnd: vToleranceEnd
      } = value;

      q = vq;
      h = vh;
      stock = vStock;
      toleranceStart = vToleranceStart;
      toleranceEnd = vToleranceEnd;
    }

    const {
      category
    } = selectorRef.getFieldsValue();

    let finalQ: any = undefined;
    let finalH: any = undefined;

    if (q) {
      finalQ =  Number(q);
    }

    if (h) {
      finalH =  Number(h);
    }

    let param: any = {
      ts: toleranceStart,
      te: toleranceEnd,
      stock,
      c: category.selected
    };

    if (q) {
      param.q = finalQ;
    }

    if (h) {
      param.h = finalH;
    }

    // if (q && h) { // 还是需要搜索的
    setSearchParams(param);
    // }
    searchRef.setFieldsValue({ q, h, toleranceStart, toleranceEnd, stock });
    // selectorRef.setFieldsValue({ category: { selected } });
  };

  useEffect(() => {
    handleFormChange();
    // setExpanded(true);

    oldQuantity = q || '';
    oldHead = h || '';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [q, h, toleranceStart, toleranceEnd, stock, JSON.stringify(selected)]);

  const handleQuantityBlur = () => {
    let {
      q: finalValue
    } = searchRef.getFieldsValue();

    if (finalValue === '') {
      searchRef.setFieldsValue({
        q: finalValue
      });
  
      oldQuantity = finalValue;

      return;
    }

    const finalVs = `${finalValue}`.split('.');

    const first = finalVs[0] || '0';
    let two = finalVs[1] || '0';
    two = two.slice(0, 1);

    finalValue = `${first}${two ? '.' : ''}${two}`;

    searchRef.setFieldsValue({
      q: finalValue
    });

    oldQuantity = finalValue;
  }

  const handleQuantityChange = (e: any) => {
    const length = oldQuantity.length;

    const value = e.target.value;
    const lengthTwo = value.length
    
    if (lengthTwo > length) {
      let newValue = value.slice(length);
  
      newValue = newValue.replace(/\D/g, '.');
  
      let finalValue = `${oldQuantity}${newValue}`;

      searchRef.setFieldsValue({
        q: finalValue
      });
  
      oldQuantity = finalValue;
    } else {
      searchRef.setFieldsValue({
        q: value
      });
  
      oldQuantity = value;
    }
  }

  const handleHeadBlur = () => {
    let {
      h: finalValue
    } = searchRef.getFieldsValue();

    if (finalValue === '') {
      searchRef.setFieldsValue({
        h: finalValue
      });
  
      oldHead = finalValue;

      return;
    }

    const finalVs = `${finalValue}`.split('.');

    const first = finalVs[0] || '0';
    let two = finalVs[1] || '0';
    two = two.slice(0, 1);

    finalValue = `${first}${two ? '.' : ''}${two}`;

    searchRef.setFieldsValue({
      h: finalValue
    });

    oldHead = finalValue;
  }

  const handleHeadChange = (e: any) => {
    const length = oldHead.length;

    const value = e.target.value;
    const lengthTwo = value.length
    
    if (lengthTwo > length) {
      let newValue = value.slice(length);
  
      newValue = newValue.replace(/\D/g, '.');
  
      let finalValue = `${oldHead}${newValue}`;
  
      searchRef.setFieldsValue({
        h: finalValue
      });
  
      oldHead = finalValue;
    } else {
      searchRef.setFieldsValue({
        h: value
      });
  
      oldHead = value;
    }
  }

  return (
    <BaseLayout title={t("route.selection")}>
      <div className='aiko-spu-selection'>
        <PageHeader title={t("route.selection")} style={{ padding: "16px 0" }} />
        <div className='aiko-spu-selection-param'>
          <Card
            className="assp-left"
            bodyStyle={{ paddingBottom: 0 }}
          >
            <Form
              className="assp-search"
              layout="inline"
              form={searchRef}
              onFinish={handleSubmit}
            >
              <Form.Item name="q" label="Q" style={{ paddingBottom: "24px" }}>
                <Input
                  addonAfter="m³/h"
                  style={{ width: 120 }}
                  onChange={handleQuantityChange}
                  onBlur={handleQuantityBlur}
                />
              </Form.Item>
              <Form.Item name="h" label="H" style={{ paddingBottom: "24px" }}>
                <Input
                  addonAfter="m"
                  style={{ width: 110 }}
                  onChange={handleHeadChange}
                  onBlur={handleHeadBlur}
                />
              </Form.Item>
              <Form.Item
                label={t("spuSelection.tolerance")}
                style={{ paddingBottom: "24px" }}
              >
                <Space direction="horizontal">
                  <Form.Item name="toleranceStart" noStyle>
                    <InputNumber
                      addonAfter="%"
                      style={{ width: 100 }}
                      min={80}
                      max={100}
                    />
                  </Form.Item>
                  -
                  <Form.Item name="toleranceEnd" noStyle>
                    <InputNumber
                      addonAfter="%"
                      style={{ width: 100 }}
                      min={100}
                      max={140}
                    />
                  </Form.Item>
                </Space>
              </Form.Item>
              <Form.Item
                name="stock"
                label={t("spuSelection.stock")}
                style={{ paddingBottom: "24px" }}
              >
                <Select style={{ width: 140 }}>
                  <Option value="all">{t("spuSelection.all")}</Option>
                  <Option value="inStock">{t("spuSelection.inStock")}</Option>
                  <Option value="outOfStock">{t("spuSelection.outOfStock")}</Option>
                </Select>
              </Form.Item>
              <Form.Item style={{ paddingBottom: "24px" }}>
                <Space>
                  <Button type="primary" htmlType="submit">
                    {t("spuSelection.submit")}
                  </Button>
                  <Button onClick={handleReset}>{t("spuSelection.clear")}</Button>
                  <Button type="link" onClick={() => setExpanded(!expanded)}>
                    {expanded ? (
                      <>
                        {t("spuSelection.collapse")} <UpOutlined />
                      </>
                    ) : (
                      <>
                        {t("spuSelection.expand")} <DownOutlined />
                      </>
                    )}
                  </Button>
                </Space>
              </Form.Item>
            </Form>
            <Form
              className='assp-category'
              layout="vertical"
              style={{ display: expanded ? "block" : "none" }}
              form={selectorRef}
              initialValues={{ category: { selected: [] } }}
            >
              <Form.Item name="category" label={t("spuSelection.category")}>
                <CategorySelector />
              </Form.Item>
            </Form>
          </Card>
          {
            selected.length > 0 ? (
              <div className='assp-right'>
                <div className='asspr-chart'>
                  <SelectionChart
                    q={q}
                    h={h}
                    stock={stock}
                    toleranceEnd={toleranceEnd}
                    toleranceStart={toleranceStart}
                    selected={selected}
                    handleSubmit={handleSubmit}
                    handleHeadChange={handleHeadChange}
                    handleQuantityChange={handleQuantityChange}
                  />
                </div>
              </div>
            ) : null
          }
        </div>
        <Card style={{ marginTop: 24 }}>
          {
            loading ? (
              <Spin className='selection-result-spin' />
            ) : (q && h) ? (
              <div>
                <SelectionResult
                  q={q}
                  h={h}
                  filteredSpus={filteredSpus}
                />
              </div>
            ) : (
              <SelectionTable
                stock={stock}
                selected={selected}
              />
            )
          }
        </Card>
      </div>
    </BaseLayout>
  );
};

export default SpuSelection;
