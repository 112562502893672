import { Form, InputNumber } from "antd";
import { useTranslation } from "react-i18next";

import ImageSelect from "../../components/ImageSelect";
import InputName from "../../components/InputName";

interface Props {
  form: any;
  initialValues?: any;
}

const AttributeImageForm = ({ form, initialValues }: Props) => {
  const { t } = useTranslation();

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={
        initialValues || {
          name: {
            zh: "",
            en: "",
            ru: "",
          },
          order: 100,
          image: {
            path: "",
            src: 0,
          }
        }
      }
    >
      <Form.Item label={t("model.imageAttrForm.name")} name="name" required>
        <InputName />
      </Form.Item>
      <Form.Item label={t("model.imageAttrForm.sort")} name="order" required>
        <InputNumber min={0} precision={0} />
      </Form.Item>
      <Form.Item label={t("model.imageAttrForm.image")} name="image" required>
        <ImageSelect />
      </Form.Item>
    </Form>
  );
};

export default AttributeImageForm;
