import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { Button, Drawer, Form, Tooltip } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";

import useModel from "../../stores/model";

import AttributeImageForm from "./AttributeImageForm";

import useDrawer from "../../hooks/useDrawer";

const { useForm } = Form;

const CreateImage = ({
  defaultDrawerVisible = false,
  attrListActiveId,
  nowId,
  rowIndex
}: {
  defaultDrawerVisible?: boolean;
  attrListActiveId?: any;
  nowId?: any;
  rowIndex: number
}) => {
  const [form] = useForm();

  const [first, setFirst] = useState(true);

  const { appendSubAttribute } = useModel();

  const submitHook = () => {
    const values = form.getFieldsValue();
    const { name, image, order } = values;
    const { path, src } = image;
    const newAttribute = {
      order,
      path,
      src,
      name: [
        { value: name.zh, lang: "0" },
        { value: name.en, lang: "1" },
        { value: name.ru, lang: "2" },
      ],
    };

    appendSubAttribute(rowIndex, newAttribute);
  };

  const {
    visible,
    show,
    hide,
    onSubmit
  } = useDrawer(submitHook);

  const { t } = useTranslation();

  useEffect(() => {
    if (first && defaultDrawerVisible && attrListActiveId && nowId) {

      if (nowId === Number(attrListActiveId)) {
        show();
  
        setFirst(false);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultDrawerVisible, attrListActiveId, nowId]);

  return (
    <>
      <Tooltip title={t("model.addImageAttr.button")}>
        <Button type="text" icon={<PlusCircleOutlined />} onClick={show} />
      </Tooltip>
      <Drawer
        title={t("model.addImageAttr.title")}
        visible={visible}
        onClose={hide}
        destroyOnClose
        extra={
          <Button type="primary" onClick={onSubmit}>
            {t("model.addImageAttr.submit")}
          </Button>
        }
      >
        <AttributeImageForm form={form} />
      </Drawer>
    </>
  );
};

export default CreateImage;
