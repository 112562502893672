import { Button, Form, InputNumber, Radio } from "antd";
import { useTranslation } from "react-i18next";
import useSpuPoints from "../../../stores/spuPoints";

const { useForm } = Form;

const SeriesParallelForm = () => {
  const [form] = useForm();
  const { t } = useTranslation();
  const { setSeriesParallel, resetSeriesParallel } = useSpuPoints();

  const handleSubmit = (values: any) => {
    const {
      type,
      quantity
    } = values;

    if (quantity <= 0) {
      return;
    }

    setSeriesParallel({ type, quantity });
  };

  const handleReset = () => {
    form.resetFields();
    resetSeriesParallel();
  };

  return (
    <Form
      name="seriesParallelForm"
      layout="vertical"
      form={form}
      onReset={handleReset}
      onFinish={handleSubmit}
      initialValues={{ type: "series", quantity: 1 }}
    >
      <Form.Item
        label={t("seriesParallelChart.type.label")}
        name="type"
        rules={[
          {
            required: true,
            message: t("seriesParallelChart.type.error"),
          },
        ]}
      >
        <Radio.Group>
          <Radio.Button value="series">
            {t("seriesParallelChart.type.series")}
          </Radio.Button>
          <Radio.Button value="parallel">
            {t("seriesParallelChart.type.parallel")}
          </Radio.Button>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        label={t("seriesParallelChart.quantity.label")}
        name="quantity"
        rules={[
          {
            required: true,
            message: t("seriesParallelChart.quantity.error"),
          },
        ]}
      >
        <InputNumber min={1} />
      </Form.Item>
      <Form.Item noStyle>
        <Button type="primary" htmlType="submit">
          {t("seriesParallelChart.submit")}
        </Button>
        <Button type="ghost" htmlType="reset" style={{ marginLeft: 10 }}>
          {t("seriesParallelChart.clear")}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default SeriesParallelForm;
