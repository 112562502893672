import { useEffect } from "react";
import { Card, Col, Row } from "antd";
import FileList from "./FileList";
import FileGrid from "./FileGrid";
import FileToolbox from "./FileToolbox";
import useStorage from "../../stores/storage";
import FileTree from "./FileTree";

const FileDisplayArea = ({isSelect, imageSelected}: {isSelect?: boolean, imageSelected?: (item: any) => void}) => {
  const { view } = useStorage();

  const onImageSelected = (item: any) => {
    imageSelected && imageSelected(item)
  }

  if (view === "grid") return <FileGrid isSelect={isSelect} imageSelected={onImageSelected} />;
  return <FileList />;
};

const FileManagerBody = (props: any) => {
  const { isSelect, imageSelected } = props
  const {
    fetchCurrentDirectory,
    fetchFolderTree,
    updateHistory,
    currentRef,
    pathList,
  } = useStorage();

  const refresh = async () => {
    if (currentRef) {
      await fetchCurrentDirectory();
    }
    if (pathList.length === 0) {
      await fetchFolderTree();
    } else {
      updateHistory(String(currentRef), pathList[0]);
    }
  };

  const onImageSelected = (item: any) => {
    imageSelected && imageSelected(item)
  }

  useEffect(() => {
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card style={{ width: "100%" }} bodyStyle={{ padding: "16px 0px" }}>
      <FileToolbox isSelect={isSelect} />
      <Row wrap={false}>
        <Col flex="256px" style={{ margin: "0 8px 0 16px" }}>
          <FileTree />
        </Col>
        <Col flex="auto" style={{ margin: "0px 16px 0px 8px" }}>
          <FileDisplayArea isSelect={isSelect} imageSelected={onImageSelected} />
        </Col>
      </Row>
    </Card>
  );
};

const FileCard = (props: any) => {
  const { isSelect, imageSelected } = props
  const { currentRef } = useStorage();

  const onImageSelected = (item: any) => {
    imageSelected && imageSelected(item)
  }

  return <FileManagerBody key={currentRef} isSelect={isSelect} imageSelected={onImageSelected} />;
};

export default FileCard;
