import React, { useEffect, useState } from "react";
import { Form } from "antd";
import { useTranslation } from "react-i18next";
import SeriesParallelForm from "./SeriesParallelForm";
import Chart, { EChartsOption } from "../../../components/Chart";
import { DataSource } from "../../../types";
import { rangeRight } from "../../../utils";
import ChartLayout from "../../../components/ChartLayout";
import { EChartsType } from "echarts/core";
import useChartImage from "../../../hooks/useChartImage";

// may be 多的 系列并行图表

interface SeriesParallelValues {
  type: "series" | "parallel";
  quantity: number;
}

const renderTypeLabel =
  (x: number, y: number, text: string) => (_params: any, _api: any) => ({
    type: "text",
    style: {
      text,
      lineHeight: 16,
      x,
      y,
    },
    z2: 200,
  });

const SeriesParallelChart = ({ dataSource }: { dataSource: DataSource }) => {
  const { t, i18n } = useTranslation();

  const initSeries: any = [
    {
      id: 0,
      name: "original",
      type: "line",
      smooth: true,
      symbolSize: 0,
      data: dataSource.QH.dummyPoints,
      animation: false,
    },
    {
      name: "text 1",
      type: "custom",
      renderItem: renderTypeLabel(
        400,
        75,
        t("seriesParallelChart.type.series")
      ),
      data: [{ x: 0, y: 0 }],
    },
  ];

  const initOption: EChartsOption = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
      },
    },
    toolbox: {
      feature: {
        saveAsImage: { name: dataSource.name + "_sp" },
      },
    },
    xAxis: { name: "m³/h", type: "value", min: 0 },
    yAxis: { name: "H(m)", type: "value", min: 0 },
    series: initSeries,
  };

  const [option, setOption] = useState(initOption);
  const [form] = Form.useForm();

  const handleFormSubmit = ({ type, quantity }: SeriesParallelValues) => {
    const newSeries: any = rangeRight(quantity, 1).map((n) => {
      const k = n;
      const data = dataSource.QH.dummyPoints.map((p) => {
        if (type === "series") return [p[0], p[1] * k];
        else return [p[0] * k, p[1]];
      });

      return {
        name: `Line ${n}`,
        type: "line",
        symbolSize: 0,
        data,
        animation: false,
      };
    });

    setOption({
      ...initOption,
      series: [
        ...newSeries,
        {
          name: "text 1",
          type: "custom",
          renderItem: renderTypeLabel(
            400,
            75,
            type === "series"
              ? t("seriesParallelChart.type.series")
              : t("seriesParallelChart.type.parallel")
          ),
          data: [{ x: 0, y: 0 }],
        },
      ],
    });
  };

  useEffect(() => {
    const { type, quantity }: SeriesParallelValues = form.getFieldsValue();
    handleFormSubmit({ type, quantity });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  const { addImage, delImage, exportTemplate } = useChartImage();

  const resetChart = () => {
    setOption(initOption);
    form.resetFields();
    delImage("seriesParallelChart");
  };

  const handleChartChange = (instance: EChartsType) => {
    const result = instance.getDataURL(exportTemplate as any);
    const { quantity }: SeriesParallelValues = form.getFieldsValue();
    instance.getDataURL();
    if (quantity > 1) addImage("seriesParallelChart", result);
  };

  return (
    <ChartLayout
      chart={
        <Chart
          option={option}
          notMerge
          style={{ width: "500px", height: "600px", margin: "auto" }}
          onChange={handleChartChange}
        />
      }
      form={
        <SeriesParallelForm
          onFinish={handleFormSubmit}
          onReset={resetChart}
          form={form}
        />
      }
    />
  );
};

export default SeriesParallelChart;
