import "./ClassifyInfo.css";
import {
  Form,
  Input,
  InputNumber,
  Button,
  message,
  Modal,
  Image
} from 'antd'
import { SyncOutlined, CloseOutlined } from '@ant-design/icons'
import { useTranslation } from "react-i18next";
import { useState, useRef, forwardRef, useImperativeHandle } from 'react';
import classifyServices from '../../../services/classify';
import ClassifyEditor from './ClassifyEditor';
import FileCard from "../../FileManager/FileCard";
import AikonCascader from "./AikonCascader";
import useStorage from '../../../stores/storage'

const ClassifyInfo = forwardRef((data: any, ref) => {
  let { optionList, submitStatus } = data;
  const { t } = useTranslation();

  // 处理最后一级无法选中
  const treeParentId = (tree: any[], pid?: any) => {
    for (let i = 0; i < tree.length; i++) {
      let item = tree[i];
      item["parentId"] = pid;
      if(item.parentId && !item.children) {
        // item["disabled"] = true
      }
      if(item.children && item.children.length !== 0) {
        treeParentId(item.children, item.id)
      }
    }
    return tree;
  }

  if(optionList) {
    let tree = JSON.parse(JSON.stringify(optionList))
    tree.unshift({id: 0, key: 0, name: "顶级"})
    optionList = treeParentId(tree)
  }
  const { setView } = useStorage()
  const cnEditorRef = useRef<any>(null)
  const enEditorRef = useRef<any>(null)
  const ruEditorRef = useRef<any>(null)
  const [fileVisible, setFileVisible] = useState(false)
  const [updateKey, setUpdateKey] = useState<any>(null)
  const [form] = Form.useForm();
  const [formData, setFormData] = useState({
    pid: "",
    image: "",
    path: "",
    catID: "",
    orderIndex: 1,
    catePriceNum: 1,
    nameLang: [
      {id: "", value: "", lang: 0},
      {id: "", value: "", lang: 1},
      {id: "", value: "", lang: 2},
    ],
    contentLang: [
      {id: "", value: "", lang: 0},
      {id: "", value: "", lang: 1},
      {id: "", value: "", lang: 2},
    ]
  });
  let baseInfo: any = {}
  const [imgUpType, setImgUpType] = useState('image')
  const [editorInsert, setEditorInsert] = useState<any>({})
  const [langList] = useState<any>({0:"中文", 1:"English", 2:"русский"});

  // 暴露方法给父组件调用
  useImperativeHandle(ref, () => ({
    submit: onSubmit,
    create: onCreate,
    info: getInfo
  }))

  // 接收数据并赋值
  const getInfo = (info: any) => {
    baseInfo = info;
    if(info.name_lang && info.name_lang.length) {
      let nameLang = formData.nameLang;
      for(let i=0; i<nameLang.length; i++) {
        let item = nameLang[i];
        let index = info.name_lang.findIndex((iItem: {type: number, lang: number}) => iItem.type === item.lang || iItem.lang === item.lang)
        if(index > -1) {
          item.value = info.name_lang[index].value;
          item.id = info.name_lang[index].id;
          item.lang = info.name_lang[index].type || info.name_lang[index].lang;
        }
      }
      setFormData(Object.assign(formData, {nameLang: nameLang}))
      form.setFieldValue("nameLang", nameLang)
    }

    cnEditorRef.current && cnEditorRef.current.setContent("<p><p>")
    enEditorRef.current && enEditorRef.current.setContent("<p><p>")
    ruEditorRef.current && ruEditorRef.current.setContent("<p><p>")

    if(info.content_lang && info.content_lang.length) {
      let contentLang = formData.contentLang
      for(let i=0; i<contentLang.length; i++) {
        let item = contentLang[i];
        let index = info.content_lang.findIndex((iItem: {type: number, lang: number}) => iItem.type === item.lang || iItem.lang === item.lang)
        if(index > -1) {
          item.value = info.content_lang[index].value
          item.id = info.content_lang[index].id
          item.lang = info.content_lang[index].type || info.content_lang[index].lang;
        }
        if(item.lang === 0 && item.value) cnEditorRef.current && cnEditorRef.current.setContent(item.value)
        if(item.lang === 1 && item.value) enEditorRef.current && enEditorRef.current.setContent(item.value)
        if(item.lang === 2 && item.value) ruEditorRef.current && ruEditorRef.current.setContent(item.value)
      }
    }
    
    form.setFieldValue("catePriceNum", info.cate_price_num);
    form.setFieldValue("orderIndex", info.order_index);
    setFormData(Object.assign(formData, {path: info.path, image: info.image, catID: info.id}))
    form.setFieldValue("pid", info.pid)
    setUpdateKey(new Date().getTime())
  }

  // 内容提交
  const onSubmit = async () => {
    try {
      // const values: any = await form.validateFields();

      let fieldsData = form.getFieldsValue();
      let params: any = {
        pid: '',
        image: formData.image,
        catID: formData.catID,
        orderIndex: fieldsData.orderIndex,
        catePriceNum: fieldsData.catePriceNum,
        nameLang: [],
        contentLang: []
      }
      // 取最后一个值
      if(fieldsData.pid && fieldsData.pid !== 0) {
        params.pid = fieldsData.pid
      }
      fieldsData.nameLang.forEach((item: any) => {
        if(item.value) {
          params.nameLang.push(item)
        }
      })
      formData.contentLang.forEach((item: any) => {
        if(item.value) {
          params.contentLang.push(item)
        }
      })
  
      const res = await classifyServices.catInfoPut(params)
      const { response } = res.data;
      if(response) {
        submitStatus()
        message.success("Success")
      }
    } catch (error) {
      
    }
  }

  // 新建内容
  const onCreate = () => {
    setFormData(Object.assign(formData, {catID: ''}))
    onNameClear(0)
    onContentClear(0)
  }

  const onSendValue = (val: any, index: number) => {
    let contentLang = formData.contentLang;
    contentLang[index].value = val;
    setFormData(Object.assign(formData, {contentLang}));
  }

  // 清除名称
  const onNameClear = (type?: any) => {
    let nameLang = formData.nameLang;
    nameLang.forEach(item => {
      item.value = ""
      if(type === 0) item.id = ""
    })
    form.setFieldValue("nameLang", nameLang);
  }

  // 重置名称
  const onNameReset = () => {
    let nameLang = formData.nameLang;
    form.setFieldValue("nameLang", nameLang);
  }

  // 清空内容
  const onContentClear = (type?: any) => {
    let contentLang = formData.contentLang;
    contentLang.forEach(item => {
      item.value = ""
      if(type === 0) item.id = ""
    })
    setFormData(Object.assign(formData, {contentLang}));
    cnEditorRef.current && cnEditorRef.current.clearHtml()
    enEditorRef.current && enEditorRef.current.clearHtml()
    ruEditorRef.current && ruEditorRef.current.clearHtml()
  }

  // 重置内容
  const onContentReset = () => {
    for(let i=0; i<formData.contentLang.length; i++) {
      let item = formData.contentLang[i];
      item.value = ""
      item.id = ""
    }
    cnEditorRef.current && cnEditorRef.current.setContent("");
    enEditorRef.current && enEditorRef.current.setContent("");
    ruEditorRef.current && ruEditorRef.current.setContent("");
    if(baseInfo.content_lang && baseInfo.content_lang.length) {
      // let contentItem = baseInfo.content_lang[0]
      for(let i=0; i<formData.contentLang.length; i++) {
        let item = formData.contentLang[i];
        let index = baseInfo.content_lang.findIndex((iItem: {lang: number}) => iItem.lang === item.lang)
        if(index > -1) {
          item.value = baseInfo.content_lang[index].value
          item.id = baseInfo.content_lang[index].id
        }
        if(item.lang === 0) cnEditorRef.current && cnEditorRef.current.setContent(item.value);
        if(item.lang === 1) enEditorRef.current && enEditorRef.current.setContent(item.value);
        if(item.lang === 2) ruEditorRef.current && ruEditorRef.current.setContent(item.value);
      }
    }
  }

  const onImageClick = () => {
    if(formData.path) {
      setFormData({...formData, path: "", image: ""})
    } else {
      setImgUpType('image')
      setFileVisible(true)
      setView("grid")
    }
  }

  const closeFileVisible = () => {
    setFileVisible(false)
  }

  const onImageSelected = (item: any) => {
    if(imgUpType === 'image') {
      setFormData(Object.assign(formData, {image: item.id, path: item.path}))
    } else if(imgUpType === 'editor') {
      editorInsert.insertFn(item.path)
    }
    setFileVisible(false)
  }

  const onCascaderChange = (val: any) => {
    setFormData(Object.assign(formData, {pid: val}))
    form.setFieldValue('pid', val)
  }

  const imagePreview = () => {
    return (
      <div key={updateKey}>
        {formData.path?(<Image width={100} src={formData.path} />):null}
      </div>
    )
  }

  const onEditorUp = (insertFn: Function, type: any) => {
    setImgUpType('editor')
    setFileVisible(true)
    setView("grid")
    setEditorInsert({insertFn: insertFn})
  }

  return (
    <>
      <Form
        name="basic"
        labelCol={{ span: 3 }}
        wrapperCol={{ span: 20 }}
        autoComplete="off"
        form={form}
        initialValues={formData}
        colon={false}
      >
        <Form.Item label={t("classify.form.name")}>
          <div className="classify-names">
            <div className="classify-clear" style={{"marginBottom":"16px"}}>
              <Button style={{"marginRight":"8px"}} size="small" type="primary" danger onClick={onNameReset}><SyncOutlined /></Button>
              <Button type="primary" size="small" onClick={onNameClear}><CloseOutlined /></Button>
            </div>
            <Form.List name="nameLang">
              {fields =>
                fields.map(({key, name}) => (
                  <Form.Item 
                    name={[name, "value"]} 
                    label={langList[key]} 
                    labelCol={{ span: 5 }} 
                    key={key} 
                    rules={[{ required: true, message: '请输入' }]}>
                    <Input className="classify-names-input" />
                  </Form.Item>
                ))
              }
            </Form.List>
          </div>
        </Form.Item>
        <Form.Item name="catePriceNum" label={t("classify.form.price")} rules={[{ required: true, message: '请输入' }]}>
          <InputNumber 
            formatter={(value: any) => `${value}`.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3')}
            parser={(value: any) => value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            min={0} 
            stringMode
            className="classify-names-input classify-input-width"
          />
        </Form.Item>
        <Form.Item label={t("classify.form.pic")}>
          <div>
            {
              imagePreview()
            }
          </div>
          <Button 
            key={updateKey}
            style={{marginTop: formData.path?"16px":"0px"}}
            type="primary"
            size="small"
            danger={!!formData.path}
            className="classify-names-input" 
            onClick={onImageClick}>{formData.path?t("classify.form.del"):t("classify.form.select")}</Button>
        </Form.Item>
        <Form.Item name="orderIndex" label={t("classify.form.sort")} rules={[{ required: true, message: '请输入' }]}>
          <InputNumber min={1} className="classify-names-input classify-input-width" />
        </Form.Item>
        <Form.Item name="pid" label={t("classify.form.sup")} rules={[{ required: true, message: '请输入' }]}>
          {
            optionList?(
                <AikonCascader 
                  fieldNames={{ label: 'name', value: 'key', children: 'children' }} 
                  data={optionList} 
                  value={formData.pid} 
                  radioChange={onCascaderChange} />
              ): null
          }
        </Form.Item>
        <Form.Item label={t("classify.form.desc")}>
          <div className="classify-descs">
            <div className="classify-clear" style={{"margin":"14px 14px 0"}}>
              <Button style={{"marginRight":"8px"}} size="small" type="primary" danger onClick={onContentReset}><SyncOutlined /></Button>
              <Button type="primary" size="small" onClick={onContentClear}><CloseOutlined /></Button>
            </div>
            <h3>中文：</h3>
            <ClassifyEditor ref={cnEditorRef} value={formData.contentLang[0].value} sendValue={(val: any) => onSendValue(val, 0)} upImageClick={onEditorUp} />
            <h3>English：</h3>
            <ClassifyEditor ref={enEditorRef} value={formData.contentLang[1].value} sendValue={(val: any) => onSendValue(val, 1)} upImageClick={onEditorUp} />
            <h3>русский：</h3>
            <ClassifyEditor ref={ruEditorRef} value={formData.contentLang[2].value} sendValue={(val: any) => onSendValue(val, 2)} upImageClick={onEditorUp} />
          </div>
        </Form.Item>
      </Form>
      <Modal centered width="1080px" open={fileVisible} footer={null} onCancel={closeFileVisible}>
        <div style={{maxHeight: "700px", overflowY: "auto"}}>
          <FileCard isSelect={true} imageSelected={onImageSelected} />
        </div>
      </Modal>
    </>
  )
})

export default ClassifyInfo;