import { Card, Col, Row } from "antd";
import React from "react";

interface ChartLayoutProps {
  chart: React.ReactNode;
  form?: React.ReactNode;
  result?: React.ReactNode;
}

const ChartLayout = ({ chart, form, result }: ChartLayoutProps) => {
  return (
    <Card>
      <Row justify="center">
        <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={10}>
          <Card
            style={{
              maxWidth: "600px",
              marginLeft: "auto",
              marginRight: "auto",
              marginBottom: "24px",
              overflow: "auto",
            }}
          >
            {chart}
          </Card>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={10}>
          <Card style={{ maxWidth: 470, margin: "auto" }}>{form}</Card>
          {result && (
            <div style={{ maxWidth: 470, margin: "24px auto auto auto" }}>
              {result}
            </div>
          )}
        </Col>
      </Row>
    </Card>
  );
};

export default ChartLayout;
