import { useTranslation } from "react-i18next";

import { Card, Col, Row, Statistic } from "antd";

const PerformanceResult = ({
  fieldsResult
}: any) => {
  const { t } = useTranslation();

  if (!fieldsResult) {
    return <></>;
  }

  const {
    diameter,
    speed,
    frequency,
    power,
    npsh,
    eff
  } = fieldsResult;

  return (
    <Card>
      <Row gutter={18}>
        <Col span={12}>
          <Statistic
            title={t("chart.diameter")}
            value={diameter}
            precision={0}
            suffix="mm"
          />
        </Col>
        <Col span={12}>
          <Statistic
            title={t("chart.speed")}
            value={speed}
            precision={0}
            suffix="rpm"
          />
        </Col>
        <Col span={12}>
          <Statistic
            title={t("chart.frequency")}
            value={frequency}
            precision={2}
            suffix="hz"
          />
        </Col>
        <Col span={12}>
          <Statistic title="P" value={power} precision={2} suffix="kw" />
        </Col>
        <Col span={12}>
          <Statistic title="NPSHr" value={npsh} precision={2} suffix="m" />
        </Col>
        <Col span={12}>
          <Statistic
            title={t("chart.efficiency")}
            value={eff}
            precision={2}
            suffix="%"
          />
        </Col>
      </Row>
    </Card>
  );
};

export default PerformanceResult;
