
import '@wangeditor/editor/dist/css/style.css' // 引入 css
import "./ClassifyEditor.css";

import { useState, useEffect, forwardRef, useImperativeHandle, useRef } from 'react';
import { Editor, Toolbar } from '@wangeditor/editor-for-react'
import { IToolbarConfig, i18nChangeLanguage,  } from '@wangeditor/editor'
import { useTranslation } from "react-i18next";

const ClassifyEditor = forwardRef((props: any, ref) => {
  const { upImageClick } = props
  const { t } = useTranslation()
  const editorRef = useRef<any>()
  
  // editor 实例
  const [editor, setEditor] = useState<any | null>(null);

  // 编辑器内容
  const [html] = useState('');

  // 工具栏配置
  const toolbarConfig: Partial<IToolbarConfig> = { 
    toolbarKeys: [
      "bold", 
      "fontSize", 
      "italic", 
      "underline", 
      "insertTable", 
      {
        key: 'group-list', // 必填，要以 group 开头
        title: '列表', // 必填
        iconSvg: '<svg viewBox="0 0 1024 1024"><path d="M384 64h640v128H384V64z m0 384h640v128H384v-128z m0 384h640v128H384v-128zM0 128a128 128 0 1 1 256 0 128 128 0 0 1-256 0z m0 384a128 128 0 1 1 256 0 128 128 0 0 1-256 0z m0 384a128 128 0 1 1 256 0 128 128 0 0 1-256 0z"></path></svg>', // 可选
        menuKeys: ["numberedList", "bulletedList"]
      }, 
      {
        key: "group-justify",
        title: "对齐",
        iconSvg: "<svg viewBox=\"0 0 1024 1024\"><path d=\"M768 793.6v102.4H51.2v-102.4h716.8z m204.8-230.4v102.4H51.2v-102.4h921.6z m-204.8-230.4v102.4H51.2v-102.4h716.8zM972.8 102.4v102.4H51.2V102.4h921.6z\"></path></svg>",
        menuKeys: [
            "justifyLeft",
            "justifyRight",
            "justifyCenter",
            "justifyJustify"
        ]
      },
      "undo", 
      "redo", 
      "uploadImage"]
  }

  // 编辑器配置 IEditorConfig
  const editorConfig: Partial<any> = {
      placeholder: '请输入内容...',
      MENU_CONF: {}
  }

  editorConfig.MENU_CONF['fontSize'] = {
    fontSizeList: [ "x-small", "small", "normal", "large", "x-large", "xx-large" ]
  }

  editorConfig.MENU_CONF['uploadImage'] = {
    // 自定义选择图片
    customBrowseAndUpload(insertFn: any) {   // TS 语法
        // insertFn(url, alt, href)
        upImageClick(insertFn)

        // insertFn("https://image.hiai123.com/d7cdc202305291040488575.jpg")
    }
  }

  useImperativeHandle(ref, () => ({
    clearHtml: clear,
    setContent: setContent
  }))

  const setContent = (val: any) => {
    editor.setHtml(val)
  }

  const clear = () => {
    editor.clear()
  }

  const onEditorChange = (editor: any) => {
    props.sendValue(editor.getHtml())
  }

  const initEditor = (e: any) => {
    setEditor(e)
  }

  useEffect(() => {
    if(t("chart.language") !== '中文') {
      i18nChangeLanguage('en')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // 及时销毁 editor ，重要！
  useEffect(() => {
    
    return () => {
        if (editor == null) return
        editor.destroy()
        setEditor(null)
    }
  }, [editor])

  return (
    <>
    {/* defaultConfig={toolbarConfig} */}
      <div style={{ border: '1px solid #ccc', zIndex: 100, maxWidth: '800px'}}>
        
        <Toolbar
            defaultConfig={toolbarConfig}
            editor={editor}
            mode="default"
            style={{ borderBottom: '1px solid #ccc' }}
        />
        <Editor
            defaultConfig={editorConfig}
            value={html}
            onCreated={(e) => initEditor(e)}
            onChange={(editor) => onEditorChange(editor)}
            mode="default"
            style={{ height: '300px', overflowY: 'hidden' }}
        />
      </div>
      <div ref={editorRef}></div>
    </>
  )
})

export default ClassifyEditor;