import axios from "../libs/axios";


// 返回文档列表
const getList = async () => await axios.get("/document/select");

// 删除spu文档信息
const documentDelete = async (params: {id: number}) => await axios.post("/document/delete", params);

// 编辑spu文档信息
interface DocumentEdit {
  id: number
  documentName: string
  documentFile: string
  bucket?: string
}
const documentUpdate = async (params: DocumentEdit) => await axios.post("/document/update", params);

// 供应商信息提交
const documentInsert = async (params: {documentName: string, documentFile: string, bucket?: string}) => await axios.post("/document/insert", params);

const documentServices = {
  getList,
  documentInsert,
  documentDelete,
  documentUpdate
};

export default documentServices;