import {
  Card,
  Empty,
  PageHeader,
  Button,
  Spin,
  Modal,
  Form,
  Popconfirm,
  Table,
  Tabs,
  Drawer,
  Space,
} from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import BaseLayout from "../../components/BaseLayout";
import PerformanceChart from "./components/PerformanceChart";
import spuServices from "../../services/spu";
import { Dataset, DataSource } from "../../types";
import { parseDataset } from "../../utils";
import { useTranslation } from "react-i18next";
import useLoading from "../../hooks/useLoading";
import useAuth from "../../hooks/useAuth";
import SpuForm from "./components/SpuForm";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { RowDataType } from "../../components/EditableTable";
import SpeedChart from "./components/SpeedChart";
import SeriesParallelChart from "./components/SeriesParallelChart";
import CustomForm from "./components/SettingForm";
import SpuInfo from "./components/SpuInfo";
import PdfExport from "./components/PdfExport";
import {
  SkuData,
  SpuCategory,
  SpecificationData,
  SpuAttributeData,
  SpuImageAttributeData,
  SpuTextAttributeData,
  SpuInfoData,
  PdfParameterValues,
} from "../../types";
import useChartImage from "../../hooks/useChartImage";
import { useForm } from "antd/es/form/Form";
// import PSChart from "./components/PSChart";

const viewTableColumns = [
  {
    key: "q",
    title: "Q",
    dataIndex: "q",
    ellipsis: true,
  },
  {
    key: "h",
    title: "H",
    dataIndex: "h",
    ellipsis: true,
  },
  {
    key: "eff",
    title: "EFF",
    dataIndex: "eff",
    ellipsis: true,
  },
  {
    key: "npsh",
    title: "NPSHr",
    dataIndex: "npsh",
    ellipsis: true,
  },
];

const formToRequestData = (id: string, values: any) => {
  const dataset = values.dataset.reduce(
    (pre: any, cur: any) => ({
      q: [...pre.q, cur.q],
      h: [...pre.h, cur.h],
      eff: [...pre.eff, cur.eff],
      npsh: [...pre.npsh, cur.npsh],
    }),
    {
      q: [],
      h: [],
      eff: [],
      npsh: [],
    }
  );

  return {
    spu_id: Number(id),
    valid_start: values.start,
    valid_end: values.end,
    use_formula: values.formula,
    q_coordinates: dataset.q,
    h_coordinates: dataset.h,
    eff_coordinates: dataset.eff,
    npsh_coordinates: dataset.npsh.includes(null) ? [] : dataset.npsh,
    size: values.size,
    freq: values.freq,
    speed: values.speed,
  };
};

const parseSku = (sku: any[]): SkuData[] => {
  return sku.map((item: any) => ({
    id: item.id,
    sku: item.sku,
    skuId: item.sku_id,
    spuId: item.spu_id,
    key: item.key,
    keyName: item.key_name,
    isDefault: item.is_default,
  }));
};

const parseCategory = (dataSource: any): SpuCategory => ({
  id: dataSource.info.cat_id,
  name: dataSource.info.cate_name,
  image: dataSource.info.cate_sImg,
  image2x: dataSource.info.cate_bImg,
  content: dataSource.info.cate_content,
});

const parseAttrs = (attrs: any[], type: number) => {
  return attrs
    .filter((attr) => attr.type === type)
    .map((attr) => {
      const result = {
        name: attr.attr,
        type: attr.type,
        src: attr.src,
        item: attr.attr_item,
      };

      // 属性 or 文本属性
      if (type === 0 || type === 2) {
        return result;
      }

      // 图片属性
      return {
        ...result,
        path: attr.path,
        image: attr.sImg,
        image2x: attr.bImg,
      };
    });
};

const parseSpecifications = (spec: any[]): SpecificationData[] => {
  return spec.map((item: any) => ({
    id: item.id,
    name: item.name_lang,
    keyName: item.name,
    shortName: item.short_name,
    children: item.spec_item.map((child: any) => ({
      id: child.id,
      name: child.name_lang,
      keyName: child.name,
      shortName: child.short_name,
    })),
  }));
};

const parseInfo = (dataSource: any): SpuInfoData => {
  // sku 列表
  const sku: SkuData[] = parseSku(dataSource.sku);
  // 分类
  const category: SpuCategory = parseCategory(dataSource);
  // 属性
  const attrs: any[] = dataSource.attr_link;
  const attributes: SpuAttributeData[] = parseAttrs(attrs, 0);
  // 图片属性
  const imageAttributes = parseAttrs(attrs, 1) as SpuImageAttributeData[];
  // 介绍
  const textAttributes = parseAttrs(attrs, 2).filter(
    (attr) => attr.item !== ""
  ) as SpuTextAttributeData[];
  // 规格
  const specifications: SpecificationData[] = parseSpecifications(
    dataSource.spec
  );
  // 详情（图片）
  const details = {
    image: dataSource.info.tec_sImg,
    image2x: dataSource.info.tec_bImg,
  };
  // 其他
  const content = dataSource.info.content;
  // 标签
  const label = {
    size: ["default", ...dataSource.label.size_label],
    attribute: ["default", ...dataSource.label.attribute_label],
  };

  return {
    id: dataSource.info.id,
    spu: dataSource.info.spu,
    spuName: dataSource.info.spu_name,
    image: dataSource.info.sImg,
    image2x: dataSource.info.bImg,
    sku,
    createdAt: dataSource.info.create_time,
    updatedAt: dataSource.info.modify_time,
    category,
    attributes,
    specifications,
    details,
    textAttributes,
    imageAttributes,
    content,
    label
  };
};

const parsePdfParameter = (dataSource: any) => {
  const { lang, page_set, company_head } = dataSource;
  const { model_view, spu } = lang;

  return {
    modules: model_view.map((item: any) => ({
      key: item.key,
      name: item.desc,
      checked: true,
    })),
    paperSize: page_set.format,
    margin: {
      top: page_set.margin_top,
      right: page_set.margin_right,
      bottom: page_set.margin_bottom,
      left: page_set.margin_left,
    },
    company: {
      name: company_head.name,
      founder: company_head.founder,
      email: company_head.email,
      phone: company_head.mobile,
      date: company_head.date,
    },
    filename: spu,
  };
};

// spu old 好像用不到了

const SpuChart = () => {
  const [dataSource, setDataSource] = useState<DataSource | null>(null);
  const [rowData, setRowData] = useState<RowDataType[] | null>(null);
  const [spuInfo, setSpuInfo] = useState<SpuInfoData | null>(null);
  const [pdfParameter, setPdfParameter] = useState<PdfParameterValues | null>(
    null
  );
  const [dataset, setDataset] = useState<Dataset | null>(null);
  const { id } = useParams() as { id: string };
  const { isAdmin } = useAuth();
  const { t } = useTranslation();
  const { xs } = useBreakpoint();

  const getPdfParameter = async (id: string) => {
    try {
      const result = await spuServices.getPdfParameter(id);
      const data = result.data.response;
      const newPdfParameter: any = parsePdfParameter(data);

      setPdfParameter(newPdfParameter);
    } catch (error) {
      // TODO: 错误处理
      console.error(error);
    }
  };

  const getSpuPoints = async (id: string) => {
    try {
      const result = await spuServices.getPoints(id);
      const data = result.data.response;

      setDataset({
        name: data.spu,
        range: {
          start: data.valid_start,
          end: data.valid_end,
        },
        type: data.use_formula,
        diameter: data.size,
        frequency: data.freq,
        speed: data.speed,
        Q: data.q_coordinates,
        H: data.h_coordinates,
        EFF: data.eff_coordinates,
        NPSH: data.npsh_coordinates,
      });

      setRowData(
        data.q_coordinates.map(
          (q: number, i: number): RowDataType => ({
            key: String(i),
            q,
            h: data.h_coordinates[i],
            eff: data.eff_coordinates[i],
            npsh:
              data.npsh_coordinates.length === 0
                ? undefined
                : data.npsh_coordinates[i],
          })
        )
      );
    } catch (error) {
      // TODO: 错误处理
      console.error(error);
    }
  };

  const getSpuInfo = async (id: string) => {
    try {
      const result = await spuServices.getInfo(id);
      const data = result.data.response;
      const info = parseInfo(data);

      setSpuInfo(info);
    } catch (error) {
      // TODO: 错误处理
      console.error(error);
    }
  };

  const handleLabelChange = async (
    id: string,
    sizeLabel: string,
    attrLabel: string
  ) => {
    if (!spuInfo) return;
    if (sizeLabel === "default" && attrLabel === "default") return;

    const result = await spuServices.getSizeAndAttrByLabel(
      id,
      sizeLabel,
      attrLabel
    );
    const data = result.data.response;
    let newInfo = {};

    // 尺寸
    const { size } = data;
    if (size) {
      newInfo = {
        otherContent: size,
      };
    }

    // 属性
    const { attribute } = data;
    if (attribute) {
      const attributes = attribute.map((item: any) => ({
        name: item.attr,
        item: item.attr_item,
        src: 0,
        type: 0,
      }));
      newInfo = {
        ...newInfo,
        otherAttributes: attributes,
      };
    }

    // 图片
    const { size_image } = data;
    if (size_image) {
      const images = [
        {
          path: size_image,
          image: size_image,
          image2x: size_image,
        },
      ];
      newInfo = {
        ...newInfo,
        otherImageAttributes: images,
      };
    }

    setSpuInfo({
      ...spuInfo,
      ...newInfo,
    });
  };

  const handleIdChange = async (id: string) => {
    await getSpuInfo(id);
    await getPdfParameter(id);
    await getSpuPoints(id);
  };

  useEffect(() => {
    if (dataset) setDataSource(parseDataset(dataset));
  }, [dataset]);

  const [onIdChange, loading] = useLoading(handleIdChange);
  const { delAllImages } = useChartImage();

  useEffect(() => {
    delAllImages();
    onIdChange(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // 创建数据点
  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);
  const [form] = Form.useForm();

  const handleCreateOk = async () => {
    const values = await form.validateFields();

    const points = formToRequestData(id, values);
    await spuServices.postPoints(id, points);

    setIsCreateModalVisible(false);
    form.resetFields();
    onIdChange(id);
  };

  const [onModalOk, uploading] = useLoading(handleCreateOk);

  // 数据点删除
  const handleDelete = async () => {
    await spuServices.deletePoints(id);
    setDataSource(null);
    onIdChange(id);
  };

  const [onDelete, deleting] = useLoading(handleDelete);

  const deleteButton = (
    <Popconfirm
      okText={t("confirm.ok")}
      cancelText={t("confirm.cancel")}
      title={t("spuForm.delete.confirm")}
      onConfirm={onDelete}
      key="delete"
    >
      <Button type="default" loading={deleting}>
        {t("spuList.action.delete")}
      </Button>
    </Popconfirm>
  );

  // 数据点编辑
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [editForm] = Form.useForm();

  const handleEditOk = async () => {
    const values = await editForm.validateFields();

    const points = formToRequestData(id, values);
    await spuServices.putPoints(id, points);

    setIsEditModalVisible(false);
    onIdChange(id);
  };

  const [onEditOk, updating] = useLoading(handleEditOk);

  const editButton = (
    <Button
      key="edit"
      type="primary"
      onClick={() => setIsEditModalVisible(true)}
    >
      {t("spuList.action.edit")}
    </Button>
  );

  // 数据点查看
  const [isViewModalVisible, setIsViewModalVisible] = useState(false);

  const viewButton = (
    <Button
      key="view"
      type="default"
      onClick={() => setIsViewModalVisible(true)}
    >
      {t("spuList.action.view")}
    </Button>
  );

  // 自定义数据点
  const [drawer, setDrawer] = useState(false);

  const hideDrawer = () => {
    setDrawer(false);
  };

  const handleCustomSubmit = (values: any) => {
    const { Q, H, EFF } = values;
    if (dataset) {
      setDataset({ ...dataset, Q, H, EFF });
    }
  };

  const customButton = (
    <Button key="custom" type="default" onClick={() => setDrawer(true)}>
      {t("spuChart.Modify.title")}
    </Button>
  );

  // PDF打印
  const [pdfExportRef] = useForm();
  const { getAllImages } = useChartImage();
  const [pdfExportVisible, setPdfExportVisible] = useState(false);

  const hidePdfExport = () => setPdfExportVisible(false);
  const showPdfExport = () => setPdfExportVisible(true);

  const [specItem, setSpecItem] = useState<{ sku: string; specData: any }>();

  const handleSpecChange = (sku: string, newSpecData: any) =>
    setSpecItem({
      sku,
      specData: newSpecData,
    });

  const handlePdfExportOk = async () => {
    // 获取 PDF 打印界面的字段
    const values = await pdfExportRef.getFieldsValue();
    // 获取已经生成的图表
    const images = getAllImages();
    // 获取时间
    const date = values.company.date
      ? values.company.date.format("YYYY-MM-DD")
      : "";
    // 获取规格数据（暂无
    // 获取sku数据（暂无
    let params: any = {
      pageSet: {
        format: values.paperSize,
        margin_left: values.margin.left,
        margin_right: values.margin.right,
        margin_top: values.margin.top,
        margin_bottom: values.margin.bottom,
      },
      companyHead: {
        name: values.company.name,
        founder: values.company.founder,
        mobile: values.company.phone,
        email: values.company.email,
        date,
      },
      modelView: values.modules.map((item: any) => item.key),
      pdfData: {
        text: values.custom
          ? values.custom.map((item: any) => ({
              name: item.key,
              value: item.value,
            }))
          : [],
        images,
      },
    };

    // specData
    // sku
    if (specItem) {
      const { sku, specData } = specItem;
      params = { ...params, sku, specData };
    }

    // POST接口
    const res = await spuServices.postPdfExport(id, params);
    const { data } = res;
    const url = window.URL.createObjectURL(
      new Blob([data], { type: "application/pdf" })
    );
    const download = document.createElement("a");
    download.href = url;
    download.download = `${values.filename || pdfParameter?.filename}.pdf`;
    download.click();

    setPdfExportVisible(false);
    pdfExportRef.resetFields();
  };

  const [onPdfExportOk, pdfExporting] = useLoading(handlePdfExportOk);

  const handlePdfExportCancel = () => pdfExportRef.resetFields();

  const pdfExportButton = (
    <Button key="pdfExport" type="default" onClick={showPdfExport}>
      {t("spuChart.pdfExport.title")}
    </Button>
  );

  let toolButtons: any = [
    pdfExportButton,
    viewButton,
    customButton
  ];

  if (isAdmin) {
    toolButtons = [
      deleteButton,
      pdfExportButton,
      viewButton,
      customButton,
      editButton
    ];
  }

  const extra = xs ? toolButtons.reverse() : toolButtons;

  const empty = (
    <Card>
      <div
        style={{
          minHeight: "70vh",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Empty description={t("chart.empty")}>
          {isAdmin && (
            <>
              <Button
                type="primary"
                onClick={() => setIsCreateModalVisible(true)}
              >
                {t("chart.create")}
              </Button>
              <Modal
                title={t("spuForm.create.title")}
                visible={isCreateModalVisible}
                onCancel={() => setIsCreateModalVisible(false)}
                onOk={onModalOk}
                okText={t("confirm.ok")}
                cancelText={t("confirm.cancel")}
                confirmLoading={uploading}
              >
                <SpuForm name="create" layout="vertical" form={form} />
              </Modal>
            </>
          )}
        </Empty>
      </div>
    </Card>
  );

  return (
    <BaseLayout title={spuInfo ? spuInfo.spu : t("route.chart")}>
      <PageHeader
        title={spuInfo ? spuInfo.spu : t("route.chart")}
        style={{ padding: "16px 0" }}
        extra={dataSource && isAdmin && extra}
      />
      {loading ? (
        <Card>
          <div
            style={{
              minHeight: "70vh",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Spin />
          </div>
        </Card>
      ) : (
        <>
          <Tabs defaultActiveKey="0" size="large">
            <Tabs.TabPane tab={t("spuChart.tab.performance")} key="0">
              {dataSource ? (
                <PerformanceChart dataSource={dataSource} />
              ) : (
                empty
              )}
            </Tabs.TabPane>
            <Tabs.TabPane tab={t("spuChart.tab.speed")} key="1">
              {dataSource ? <SpeedChart dataSource={dataSource} /> : empty}
            </Tabs.TabPane>
            {/* <Tabs.TabPane tab={t("spuChart.tab.performanceAndSpeed")}>
              {dataSource ? (
                  <PSChart dataSource={dataSource} />
              ) : (
                empty
              )}
            </Tabs.TabPane> */}
            <Tabs.TabPane tab={t("spuChart.tab.seriesParallel")} key="2">
              {dataSource ? (
                <SeriesParallelChart
                  dataSource={dataSource}
                />
              ) : (
                empty
              )}
            </Tabs.TabPane>
            <Tabs.TabPane tab={t("spuChart.tab.description")} key="3">
              {spuInfo && pdfParameter && (
                <SpuInfo
                  dataSource={spuInfo}
                  onLabelChange={handleLabelChange}
                  onSpecChange={handleSpecChange}
                  pdfParameter={pdfParameter}
                />
              )}
            </Tabs.TabPane>
          </Tabs>
          {dataSource && (
            <>
              {pdfParameter && (
                <Drawer
                  title={pdfExportButton}
                  placement="right"
                  onClose={hidePdfExport}
                  visible={pdfExportVisible}
                  extra={
                    <Space>
                      <Button onClick={handlePdfExportCancel}>
                        {t("spuChart.pdfExport.reset")}
                      </Button>
                      <Button
                        type="primary"
                        onClick={onPdfExportOk}
                        loading={pdfExporting}
                      >
                        {t("spuChart.pdfExport.submit")}
                      </Button>
                    </Space>
                  }
                >
                  <PdfExport
                    formRef={pdfExportRef}
                    initialValues={pdfParameter}
                  />
                </Drawer>
              )}
              <Drawer
                title={t("spuChart.Modify.title")}
                placement="right"
                onClose={hideDrawer}
                visible={drawer}
              >
                {dataset && (
                  <CustomForm dataset={dataset} onSubmit={handleCustomSubmit} />
                )}
              </Drawer>
              <Modal
                title={t("spuForm.edit.title")}
                visible={isEditModalVisible}
                onCancel={() => setIsEditModalVisible(false)}
                onOk={onEditOk}
                okText={t("confirm.ok")}
                cancelText={t("confirm.cancel")}
                confirmLoading={updating}
                destroyOnClose
              >
                <SpuForm
                  name="edit"
                  layout="vertical"
                  form={editForm}
                  initialValues={{
                    freq: dataSource.frequency,
                    start: dataSource.range.start,
                    end: dataSource.range.end,
                    size: dataSource.diameter,
                    speed: dataSource.speed,
                    formula: dataSource.type,
                    dataset: rowData,
                  }}
                />
              </Modal>
              <Modal
                title={t("spuList.action.view")}
                visible={isViewModalVisible}
                onCancel={() => setIsViewModalVisible(false)}
                onOk={() => setIsViewModalVisible(false)}
                okText={t("confirm.ok")}
                cancelText={t("confirm.cancel")}
                destroyOnClose
              >
                <Table
                  dataSource={rowData || []}
                  columns={viewTableColumns}
                  pagination={false}
                />
              </Modal>
            </>
          )}
        </>
      )}
    </BaseLayout>
  );
};

export default SpuChart;
